<script>
  import { createEventDispatcher } from 'svelte';

  const dispatch = createEventDispatcher();

  let title = '';
  let comment = '';

  function saveTemplate(event) {
    // Prevent the form from actually submitting
    event.preventDefault();
    // Dispatch the event to the parent component with the title and comment
    dispatch('save', { title, comment });
  }

  function handleKeydown(event) {
    if (event.key === 'Escape') {
      dispatch('close');
    }
  }
</script>

<style>
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9998;
  }

  .popup {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: 750px;
    max-height: 90vh;
    overflow-y: auto;
  }

  .popup-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

  .popup-header h3 {
    margin: 0;
  }

  .popup-header button {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
  }
</style>

<div class="overlay" on:click="{() => dispatch('close')}" on:keydown={handleKeydown}>
  <form class="popup" on:submit|preventDefault={saveTemplate} on:click|stopPropagation on:keydown|stopPropagation on:keyup|stopPropagation>
    <div class="popup-header">
      <h3 class="text-lg font-semibold">Abfrage speichern</h3>
      <button type="button" on:click={() => dispatch('close')}>×</button>
    </div>
    <div class="mb-4">
      <label for="title" class="block mb-2 text-sm font-medium">Titel:</label>
      <input id="title" type="text" class="w-full p-2 border border-gray-300 rounded" bind:value={title} required />
    </div>
    <div class="mb-4">
      <label for="comment" class="block mb-2 text-sm font-medium">Kommentar:</label>
      <textarea id="comment" class="w-full p-2 border border-gray-300 rounded" bind:value={comment}></textarea>
    </div>
    <button
      class="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-700 active:bg-red-800"
      type="submit"
    >
      Abfrage speichern
    </button>
  </form>
</div>
