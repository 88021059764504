// CardSettingForm.js

// Common options for "ja" and "nein"
const jaNeinOptions = [
  { idPrefix: "ja", namePrefix: "ja", label: "ja" },
  { idPrefix: "nein", namePrefix: "nein", label: "nein" },
];


// Function to format date as YY-MM-dd
function formatDateAsYYMMDD(date) {
  const year = String(date.getFullYear()); // Get the last two digits of the year
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}


// Sample data structure for form fields
export const formDataStructure = [
  {
    section: "KLINISCHE DATEN",
    subsections: [
      {
        title: "Körperliche Untersuchung und soziodemographische Angaben",
        fields: [
          {
            type: "checkbox-group",
            label: "Geschlecht",
            options: [
              { id: "male", name: "geschlecht_male", label: "männlich" },
              { id: "female", name: "geschlecht_female", label: "weiblich" },
              { id: "unbekannt", name: "geschlecht_unbekannt", label: "unbekannt" },
            ],
          },
          { type: "checkbox", id: "alter", name: "alter",
             info: "Wenn die Checkbox ohne Angabe von Werten ausgewählt wurde, wird ein Standardbereich von 0 bis 1000 eingestellt, um alle vorhandenen Werte anzeigen zu können.",
             label: "Alter" , inputs: [{ type: "number"}, { type: "number" }], ranges: [0 ,120]},
          { type: "checkbox", id: "groesse", name: "groesse", label: "Körpergröße",
          inputs: [{ type: "number", unit: "cm" }, { type: "number", unit: "cm" }], ranges: [140 ,206]},
          { type: "checkbox", id: "gewicht", name: "gewicht", label: "Gewicht",
            inputs: [{ type: "number", unit: "kg" }, { type: "number", unit: "kg" }], ranges: [45 ,140]},
          { type: "checkbox", id: "bmi", name: "bmi", label: "Body Mass Index (WHO-Definition)" ,
            info:"Falls ein Konflikt zwischen dem Gewicht und der Größe besteht, ist das Ergebnis gleich 0.",
            inputs: [{ type: "number" }, { type: "number" }] , ranges: [15.5 ,57.4]},
          {
            type: "checkbox-group",
            label: "Familiäre Disposition",
            options: jaNeinOptions.map(option => ({
              id: `fam_${option.idPrefix}`,
              name: `fam_disposition_${option.namePrefix}`,
              label: option.label,
            })),
          },
        ],
      },
      {
        title: "Kardiovaskuläre Risikofaktoren",
        fields: [
          {
            type: "checkbox-group",
            label: "Diabetes mellitus",
            options: jaNeinOptions.map(option => ({
              id: `diabetes_${option.idPrefix}`,
              name: `diabetes_${option.namePrefix}`,
              label: option.label,
            })),
          },
          {
            type: "checkbox-group",
            label: "Arterielle Hypertonie",
            options: jaNeinOptions.map(option => ({
              id: `hypertonie_${option.idPrefix}`,
              name: `hypertonie_${option.namePrefix}`,
              label: option.label,
            })),
          },
          {
            type: "checkbox-group",
            label: "Dyslipidämie",
            options: jaNeinOptions.map(option => ({
              id: `dyslipidamie_${option.idPrefix}`,
              name: `dyslipidamie_${option.namePrefix}`,
              label: option.label,
            })),
          },
          {
            type: "checkbox-group",
            label: "Raucher",
            options: [
              { id: "raucher_ja", name: "raucher_ja", label: "ja" },
              { id: "raucher_nein", name: "raucher_nein", label: "nein" },
              { id: "ex_raucher", name: "ex_raucher", label: "Ex-Raucher (vor >= 6 Mon. aufgehört )" },
            ],
          },
          { type: "checkbox", id: "ex_raucher_seit", name: "ex_raucher_seit", label: "Ex-Raucher seit",
            info: "Bitte das genaue Jahr angeben!" ,inputs: [{ type: "number" }], ranges: [1800 , new Date(Date.now()).getFullYear()]},
          {
            type: "checkbox-group",
            label: "Aktuelle Dialysepflicht",
            options: jaNeinOptions.map(option => ({
              id: `dialyse_${option.idPrefix}`,
              name: `dialyse_${option.namePrefix}`,
              label: option.label,
            })),
          },
        ],
      },
      {
        title: "Kardiale Diagnosen (Anamnese und Vorbefunde)",
        fields: [
          {
            type: "checkbox-group",
            label: "Koronare Herzkrankheit",
            options: jaNeinOptions.map(option => ({
              id: `koronare_${option.idPrefix}`,
              name: `koronare_${option.namePrefix}`,
              label: option.label,
            })),
          },
          {
            type: "checkbox-group",
            label: "Zustand nach Myokardinfarkt",
            options: jaNeinOptions.map(option => ({
              id: `myokardinfarkt_${option.idPrefix}`,
              name: `myokardinfarkt_${option.namePrefix}`,
              label: option.label,
            })),
          },
          {
            type: "checkbox-group",
            label: "Kardiomyopathie",
            options: jaNeinOptions.map(option => ({
              id: `kardiomyopathie_${option.idPrefix}`,
              name: `kardiomyopathie_${option.namePrefix}`,
              label: option.label,
            })),
          },
          {
            type: "checkbox-group",
            label: "Herzinsuffizienz",
            options: jaNeinOptions.map(option => ({
              id: `herzinsuffizienz_${option.idPrefix}`,
              name: `herzinsuffizienz_${option.namePrefix}`,
              label: option.label,
            })),
          },
          {
            type: "checkbox-group",
            label: "Aktuelle oder frühere Herzklappenerkrankung",
            options: jaNeinOptions.map(option => ({
              id: `herzklappenerkrankung_${option.idPrefix}`,
              name: `herzklappenerkrankung_${option.namePrefix}`,
              label: option.label,
            })),
          },
          {
            type: "checkbox-group",
            label: "Angeborener Herzfehler",
            options: jaNeinOptions.map(option => ({
              id: `angeborener_herzfehler_${option.idPrefix}`,
              name: `angeborener_herzfehler_${option.namePrefix}`,
              label: option.label,
            })),
          },
        ],
      },
      {
        title: "Bisherige kardiovaskuläre Interventionen",
        fields: [
          {
            type: "checkbox-group",
            label: "Interventionelle koronare Revaskularisation",
            options: jaNeinOptions.map(option => ({
              id: `revaskularisation_${option.idPrefix}`,
              name: `revaskularisation_${option.namePrefix}`,
              label: option.label,
            })),
          },
          {
            type: "checkbox-group",
            label: "Herzklappen-Operation",
            options: jaNeinOptions.map(option => ({
              id: `herzklappen_operation_${option.idPrefix}`,
              name: `herzklappen_operation_${option.namePrefix}`,
              label: option.label,
            })),
          },
          {
            type: "checkbox-group",
            label: "Implantierter Schrittmacher/Defibrillator",
            options: jaNeinOptions.map(option => ({
              id: `herzschrittmacher_${option.idPrefix}`,
              name: `herzschrittmacher_${option.namePrefix}`,
              label: option.label,
            })),
          },
        ],
      },
      {
        title: "Aktuelle Nebendiagnose",
        fields: [
          {
            type: "checkbox-group",
            label: "pAVK",
            options: jaNeinOptions.map(option => ({
              id: `pavk_${option.idPrefix}`,
              name: `pavk_${option.namePrefix}`,
              label: option.label,
            })),
          },
          {
            type: "checkbox-group",
            label: "Schlaganfall/TIA",
            options: jaNeinOptions.map(option => ({
              id: `schlaganfall_${option.idPrefix}`,
              name: `schlaganfall_${option.namePrefix}`,
              label: option.label,
            })),
          },
          {
            type: "checkbox-group",
            label: "Chronische Lungenerkrankung",
            options: jaNeinOptions.map(option => ({
              id: `lungenerkrankung_${option.idPrefix}`,
              name: `lungenerkrankung_${option.namePrefix}`,
              label: option.label,
            })),
          },
          {
            type: "checkbox-group",
            label: "Depression",
            options: jaNeinOptions.map(option => ({
              id: `depression_${option.idPrefix}`,
              name: `depression_${option.namePrefix}`,
              label: option.label,
            })),
          },
          {
            type: "checkbox-group",
            label: "Krebserkrankungen vor mehr als 5 Jahren",
            options: jaNeinOptions.map(option => ({
              id: `krebserkrankung_5plus_${option.idPrefix}`,
              name: `krebserkrankung_5plus_${option.namePrefix}`,
              label: option.label,
            })),
          },
          {
            type: "checkbox-group",
            label: "Krebserkrankungen vor weniger als 5 Jahren",
            options: jaNeinOptions.map(option => ({
              id: `krebserkrankung_5minus_${option.idPrefix}`,
              name: `krebserkrankung_5minus_${option.namePrefix}`,
              label: option.label,
            })),
          },
        ],
      },
      {
        title: "Blutdruck nach 5 Minuten Ruhe",
        fields: [
          { type: "checkbox", id: "systolisch", name: "systolisch",
            label: "Systolischer Blutdruck nach 5 Minuten Ruhe", 
            inputs: [{ type: "number", unit: "mmHg" }, { type: "number", unit: "mmHg" }],
            ranges: [80 ,220] },
          { type: "checkbox", id: "diastolisch", name: "diastolisch", 
            label: "Diastolischer Blutdruck nach 5 Minuten Ruhe", 
            inputs: [{ type: "number", unit: "mmHg" }, { type: "number", unit: "mmHg" }],
            ranges: [40 ,150] },
        ],
      },
      {
        title: "Herzfrequenz nach 5 Minuten Sitzen",
        fields: [
          { type: "checkbox", id: "herzfrequenz", name: "herzfrequenz",
             label: "Herzfrequenz nach 5 Minuten Sitzen",
              inputs: [{ type: "number", unit: "cm" }, { type: "number", unit: "bpm" }],
              ranges: [32 ,180] },
        ],
      },
      {
        title: "Labordiagnostik Blut",
        fields: [
          { type: "checkbox", id: "blutentnahme", name: "blutentnahme",
             label: "Datum der Blutentnahme", inputs: [{ type: "date" }, { type: "date" }],
             ranges: [formatDateAsYYMMDD(new Date(2015, 4, 23)), formatDateAsYYMMDD(new Date(Date.now()))]},
          {
            type: "checkbox", 
            id: "haemoglobin", 
            name: "haemoglobin", 
            label: "Hämoglobin roh",
            info:"Bitte eine Einheit auswählen. Alle vorhandenen Werte werden nach dieser umgerechnet.",
            inputs: [
              { type: "number", unit: ["mmol/l", "g/dl"] },  // Multiple units as dropdown
              { type: "number", unit: ["mmol/l", "g/dl"] }
            ], ranges: [2 ,30]
          },
          {
            type: "checkbox", 
            id: "kreatinin", 
            name: "kreatinin", 
            label: "Kreatinin roh", 
            info:"Bitte eine Einheit auswählen. Alle vorhandenen Werte werden nach dieser umgerechnet.",
            inputs: [
              { type: "number", unit: ["mg/dl", "μmol/ml"] },  // Multiple units as dropdown
              { type: "number", unit: ["mg/dl", "μmol/ml"] }
            ], ranges: [0 ,16000]
          },
          {
            type: "checkbox", 
            id: "gesamtcholesterol", 
            name: "gesamtcholesterol", 
            label: "Cholesterin roh", 
            info:"Bitte eine Einheit auswählen. Alle vorhandenen Werte werden nach dieser umgerechnet.",
            inputs: [
              { type: "number", unit: ["mmol/dl", "mg/dl"] },  // Multiple units as dropdown
              { type: "number", unit: ["mmol/dl", "mg/dl"] }
            ], ranges: [0 ,300]
          }
        ],
      },
      {
        title: "Frauenspezifische anamnestische Fragen",
        fields: [
          {
            type: "checkbox-group",
            label: "Menopause",
            options: jaNeinOptions.map(option => ({
              id: `menopause_${option.idPrefix}`,
              name: `menopause_${option.namePrefix}`,
              label: option.label,
            })),
          },
          { type: "checkbox", id: "jahr_der_menopause", name: "jahr_der_menopause", label: "Jahr der Menopause",
            info:"Hier keine Datumsangaben verwenden. Bitte die genauen Jahreszahlen angeben, bspw. „2015“ bis „2022“.",
            inputs: [{ type: "number"}, { type: "number"}], ranges: [1900 ,2020] },
        ],
      },
    ],
  },
  {
    section: "BIOPROBEN",
    subsections: [
      {
        title: "Bioprobenart",
        info: "Die gewählte absolute Zahl, stellt die Anzahl der Aliquots dar, die der Studienteilnehmende für die gewählte Bioprobenart mindestens hatte. Die Zahl liegt zwischen 0 und 10. Falls lediglich die Checkbox ausgewählt wurde, wird standardmäßig der Wert 0 eingestellt, welches alle Probenanzahlen für diese Kategorie einschließt.",
        fields: [
          {
            type: "checkbox", id: "Citrat-Plasma", name: "Citrat-Plasma", 
            label: "Citrat-Plasma Aliquots (á 300µl)", inputs: [{ type: "number", unit: "" }],
            ranges: [1 ,10]
          },
          {
            type: "checkbox", id: "EDTA-Plasma", name: "EDTA-Plasma", 
            label: "EDTA-Plasma Aliquots (á 300µl)", inputs: [{ type: "number", unit: "" }],
            ranges: [1 ,30]
          },
          {
            type: "checkbox", id: "Buffycoat", name: "Buffycoat", 
            label: "Buffycoat Aliquots (á <300µl)", inputs: [{ type: "number", unit: "" }],
            ranges: [1 ,30]
          },
          {
            type: "checkbox", id: "Serum", name: "Serum", 
            label: "Serum Aliquots (á 300µl)", inputs: [{ type: "number", unit: "" }],
            ranges: [1 ,30]
          },
          {
            type: "checkbox", id: "Urin", name: "Urin", label: "Urin Aliquots (á 300µl)", 
            inputs: [{ type: "number", unit: "" }], ranges: [1 ,30]
          },

        ],
      },
    ],
  },
  {
    section: "BILD- & BIOSIGNALDATEN",
    subsections: [
      {
        title: "Art der BILD- & BIOSIGNALDATEN",
        fields: [
          {
            type: "checkbox",
            label: "Echokardiographie",
            id: "Echokardiographie",
            name: "Echokardiographie"
          },
          {
            type: "checkbox",
            label: "Magnetresonanz",
            id: "Magnetresonanztherapie",
            name: "Magnetresonanztherapie"
          },         
           {
            type: "checkbox",
            label: "Elektrokardiographie",
            id: "Elektrokardiographie",
            name: "Elektrokardiographie"
          },          {
            type: "checkbox",
            label: "Röntgen-Angiographie",
            id: "Röntgenangiographie",
            name: "Röntgenangiographie"
          },
        ],
      },
    ],
  },
];
