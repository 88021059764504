<!-- App.svelte -->
<script>
  import { Router, Route, Link } from "svelte-routing";

  // Admin Layout
  import Admin from "./layouts/Admin.svelte";

  // No Layout Pages
  import Index from "./views/Index.svelte";

  import CardSettings from './components/Cards/CardSettings.svelte';
  import CardResults from './components/Cards/CardResults.svelte';

  export let url = "";
</script>

<Router url="{url}">
  <!-- admin layout -->
  <Route path="/" component="{Index}" />
  <Route path="admin/*admin" component="{Admin}" />
  <Route path="/results" component={CardResults} />
  <Route path="/settings" component={CardSettings} />
</Router>
