<script>
  import { onMount } from "svelte";
  import Chart from "chart.js";

  export let id;
  export let useLoadedData = true;

  let chart;
  const API_URL = process.env.API_URL;

  async function fetchData() {
    const endpoint = useLoadedData ? `${API_URL}/availability_of_biosamples_combined?use_loaded_data=true` : `${API_URL}/availability_of_biosamples_combined`;
    const response = await fetch(endpoint);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  }

  onMount(async () => {
    try {
      const responseData = await fetchData();
      const data = Object.values(responseData);
      const labels = Object.keys(responseData);

      const ctx = document.getElementById(`bar-chart-${id}`).getContext("2d");
      chart = new Chart(ctx, {
        type: "bar",
        data: {
          labels,
          datasets: [
            {
              label: "Baseline",
              backgroundColor: "#b33528",
              borderColor: "#b33528",
              data,
              fill: false,
              barThickness: 15
            },
            {
              label: "FUP",
              backgroundColor: "rgba(179, 53, 40, 0.6)", // Lighter red
              borderColor: "rgba(179, 53, 40, 0.6)",
              data: [2, 12, 5, 17, 20], // Example FUP data
              fill: false,
              barThickness: 15,
            },
          ]
        },
        options: {
          maintainAspectRatio: true,
          responsive: true,
          title: {
            display: true,
            fontColor: "#4a5568",
          },
          tooltips: {
            mode: "index",
            intersect: false,
          },
          hover: {
            mode: "nearest",
            intersect: true,
          },
          legend: {
            labels: {
              fontColor: "rgba(0,0,0,.4)",
            },
            align: "end",
            position: "bottom",
          },
          scales: {
            xAxes: [{
              display: true,
              scaleLabel: {
                display: true,
                labelString: "",
              },
              gridLines: {
                borderDash: [2],
                borderDashOffset: [2],
                color: "rgba(33, 37, 41, 0.3)",
                zeroLineColor: "rgba(33, 37, 41, 0.3)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            }],
            yAxes: [{
              display: true,
              scaleLabel: {
                display: true,
                labelString: "Anzahl von Proben",
              },
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                borderDash: [2],
                drawBorder: false,
                borderDashOffset: [2],
                color: "rgba(33, 37, 41, 0.2)",
                zeroLineColor: "rgba(33, 37, 41, 0.15)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            }],
          },
        }
      });
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  });
</script>

<style>
  .chart-container {
    position: relative;
    width: 100%;
    height: 349px;
  }

  canvas {
    display: block;
    width: 100% !important;
    height: 100% !important;
  }
</style>

<div class="relative flex flex-col w-full min-w-0 break-words bg-white rounded shadow-lg">
  <div class="px-4 py-3 mb-0 bg-transparent rounded-t">
    <div class="flex flex-wrap items-center">
      <div class="relative flex-1 flex-grow w-full max-w-full">
        <h6 class="mb-1 text-xs font-semibold uppercase text-blueGray-600">
          Verfügbarkeit von Bioproben
        </h6>
      </div>
    </div>
  </div>
  <div class="flex-auto p-4">
    <div class="chart-container">
      <canvas id="bar-chart-{id}"></canvas>
    </div>
  </div>
</div>
