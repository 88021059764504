<script>
    import { onMount, onDestroy, createEventDispatcher } from 'svelte';
    import Chart from 'chart.js';
  
    export let labels;
    export let values;
    export let popupTitle;
  
    let chart;
    const dispatch = createEventDispatcher();
  
    onMount(() => {
      const ctx = document.getElementById('popup-chart-2').getContext('2d');
      chart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: labels,
          datasets: [
            {
              label: 'Studienteilnehmende',
              backgroundColor: '#b33528',
              borderColor: '#b33528',
              data: values,
              fill: false,
              barThickness: 'flex'
            }
          ]
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          title: {
            display: true,
            text: popupTitle,
            fontColor: "#4a5568",
          },
          legend: {
            display: false
          },
          scales: {
            xAxes: [{
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Aktuelles Alter'
              },
              gridLines: {
                display: false
              }
            }],
            yAxes: [{
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Studienteilnehmende'
              },
              ticks: {
                beginAtZero: true
              }
            }]
          }
        }
      });
    });
  
    function close() {
      dispatch('close');
    }
  
    onDestroy(() => {
      if (chart) chart.destroy();
    });
  </script>
  
  <style>
    .popup {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 90%;
      height: 90%;
      background-color: white;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      z-index: 1000;
      overflow: hidden;
    }
  
    .close-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: transparent;
      border: none;
      font-size: 20px;
      cursor: pointer;
    }
  
    .chart-container {
      width: 100%;
      height: 100%;
      padding: 20px;
      box-sizing: border-box;
    }
  
    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 999;
    }
  </style>
  
  <div class="overlay" on:click={close} on:keydown></div>
  <div class="popup">
    <button class="close-btn" on:click={close}>&times;</button>
    <div class="chart-container">
      <canvas id="popup-chart-2"></canvas>
    </div>
  </div>
  