<script>
  export let id;
  export let name;
  export let label;
  export let inputs = [];
</script>

<div class="input-field mb-2">
  <label for={id} class="block mb-1 font-semibold">
    <input id={id} name={name} type="checkbox" />
    {label}
  </label>
  <div class="flex space-x-2 items-center">
    {#each inputs as input, index}
      <div class="flex items-center">
        {#if input.type === "date" || input.type === "number"}
          <input id={id + '-' + index} type={input.type} class="input-field border px-2 py-1 rounded" />
          {#if index < inputs.length - 1}
            <span class="bis-text">bis</span>
          {:else if input.unit}
            <span class="unit-text">{input.unit}</span>
          {/if}
        {/if}
      </div>
    {/each}
  </div>
</div>

<style>
  .input-field {
    width: 70%;
    margin-right: 0.5rem;
  }
  .bis-text {
    margin: 0 0.5rem;
  }
  .unit-text {
    margin-left: 0.5rem;
  }
</style>
