<script>
  // make dynamic date to be added to footer
  import { link } from "svelte-routing";
  let date = new Date().getFullYear();
</script>

<footer class="block py-4">
  <div class="container px-4 mx-auto">
    <hr class="mb-4 border-b-1 border-blueGray-200" />
    <div class="flex flex-wrap items-center justify-center md:justify-between">
      <div class="w-full px-2 md:w-5/12">
        <div
          class="py-0 text-sm font-semibold text-center text-blueGray-500 md:text-left"
        >
          Copyright © {date} German Center for Cardiovascular Research eV 
          <div>(decided by Alex and Sabine)</div>
        </div>
      </div>
      <div class="w-full px-2 md:w-6/12">
        <ul class="flex flex-wrap justify-center list-none md:justify-end">
          <li>
            <a
              href="https://dzhk.de/das-dzhk/ueber-uns/mission/"
              class="block px-3 py-0 text-sm font-semibold text-blueGray-600 hover:text-blueGray-800"
            >
              Über UNS
            </a>
          </li>
          <li>
            <a
              href="https://dzhk.de/contact/"
              class="block px-2 py-0 text-sm font-semibold text-blueGray-600 hover:text-blueGray-800"
            >
              Kontakt
            </a>
          </li>
          <li>
            <a
              use:link
              href="/admin/datasecurity"
              class="block px-2 py-0 text-sm font-semibold text-blueGray-600 hover:text-blueGray-800"
            >
              Datenschutzerklärung
            </a>
          </li>
          <li>
            <a
              use:link
              href="/admin/legal_notice"
              class="block px-2 py-0 text-sm font-semibold text-blueGray-600 hover:text-blueGray-800"
            >
              Rechtliche Hinweise
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
