<script>
    // core components
    import CardBarChart_1 from "components/Cards/CardBarChart_1.svelte";
    import CardBarChart_2 from "components/Cards/CardBarChart_2.svelte";
    import CardPieChart from "components/Cards/CardPieChart.svelte";
    import CardBarChart_3 from "components/Cards/CardBarChart_3.svelte";
  
    import { onMount } from 'svelte';
  
    const API_URL = process.env.API_URL;
  
    let overviewData = {};
    let dataFetched = false;
  
    async function fetchData() {
      const response = await fetch(`${API_URL}/overview`);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    }
  
    onMount(async () => {
      try {
        const data = await fetchData();
        overviewData = data.data;
        dataFetched = true; // Set flag to true once data is fetched
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    });
  </script>
  
  {#if dataFetched}
    <div>
        <div class="flex flex-wrap">
            <div class="w-full px-4 xl:w-6/12">
                <CardBarChart_1 overviewData={overviewData}/>
            </div>
            <div class="w-full px-4 xl:w-6/12">
                <CardPieChart overviewData={overviewData}/>
            </div>
        </div>
        <div class="flex flex-wrap mt-4">
            <div class="w-full px-4 xl:w-6/12">
                <CardBarChart_2 overviewData={overviewData}/>
            </div>
            <div class="w-full px-4 xl:w-6/12">
              <CardBarChart_3 overviewData={overviewData}/>
            </div>
        </div>
    </div>
  {:else}
    <p>Loading...</p>
  {/if}
  
  