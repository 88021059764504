<script>

  import CardStats from "./CardStats.svelte";
  import CardBarChart_1 from "components/Cards/CardBarChart_1.svelte";
  import CardBarChart_2 from "components/Cards/CardBarChart_2.svelte";
  import CardPieChart from "components/Cards/CardPieChart.svelte";
  import CardBarChart_3 from "components/Cards/CardBarChart_3.svelte";
  import { currentPage } from '../../store.ts'; // Import the store
  import FilterPopup from '../Graphs/FilterPopup.svelte';
  import InfoBubble from '../Graphs/InfoBubble.svelte';

  let filterCriteria = {};
  const API_URL = process.env.API_URL;
  let showPopup = false;
  let error = null;  // To store and display any errors
  let overviewData = {};  // Initialize the overviewData object
  
  async function fetchFormData() {
    try {
      const response = await fetch(`${API_URL}/get_session_data`, {
        method: 'GET',
        credentials: 'include', // Include cookies
      });

      if (!response.ok) {
        currentPage.set('search');
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const bffresponse = await response.json();
      console.log("This is the data from the session: ", bffresponse);

      if (bffresponse){
        // Access the filterCriteria directly
        const result = bffresponse.filters;
        filterCriteria = result.filterCriteria || {};
        console.log("Filter Criteria:", filterCriteria);

        // Parse the resultData since it is a stringified JSON
        let parsedResultData;
        if (typeof result.resultData === 'string') {
          parsedResultData = JSON.parse(result.resultData);
        } else {
          parsedResultData = result.resultData;
        }

        // Use parsedResultData directly for the charts
        overviewData = parsedResultData || {};

        console.log("Parsed Overview Data:", overviewData);
      } else {
        console.log(bffresponse.message);
        throw new Error(bffresponse.message || 'Unknown error occurred');
      }
    } catch (err) {
      error = `Error fetching form data: ${err.message}`;
      console.error('Error fetching form data:', error);
      overviewData = {};  // Reset data on error
      handleBackClick();
    }
  }

  async function handleBackClick() {
    currentPage.set('search');
    window.location.href = "/admin/settings";
  }

  function firstFiveTrueEntries(object) {
    return Object.entries(object).filter(([_, value]) => value).slice(0, 5);
  }

  function handleKeyDown(event) {
    if (event.key === 'Enter' || event.key === ' ') {
      showPopup = true;
    }
  }

  function openPopup() {
    showPopup = true;
  }

  function closePopup() {
    showPopup = false;
  }

  fetchFormData();
</script>

<h2 class="text-2xl font-bold mb-4">Results   
</h2>

<!-- Selected Filters Section -->
<div class="flex flex-wrap p-3 border mb-4">
  <div class="font-bold px-4 w-full lg:w-3/12 align-text-bottom">
    <InfoBubble infoText="Es werden nur Dateifilter angezeigt. Klicken Sie darauf, um die vollständige Liste anzuzeigen." />
    <span>Ausgewählte Filter:</span>
  </div>
  <div class="flex flex-wrap w-full">
    {#if filterCriteria && Object.keys(filterCriteria).length > 0}
      {#each firstFiveTrueEntries(filterCriteria) as [_, value]}
      {#each firstFiveTrueEntries(value) as [k, v]}
        {#if v}
          <div 
            class="px-4 py-2 border bg-white m-2" 
            on:click={openPopup}
            on:keydown={handleKeyDown} 
            tabindex="0"
            role="button"
            aria-pressed="false"
          >
               {k}: {v}  
          </div>
        {/if}
        {/each}
      {/each}
    {/if}
    <div class="flex-grow flex justify-end items-right">
      <button type="button" class="px-4 py-2 bg-red-500 hover:bg-red-700 text-white rounded" on:click={handleBackClick}>zurück zur Abfrage</button>
    </div>
  </div>
</div>

<!-- Popup Modal for All Filters -->
{#if showPopup}
  <FilterPopup {filterCriteria} closePopup={closePopup} />
{/if}


<div class="flex flex-wrap mb-4 p-3 border">
  <div class="font-bold px-4 align-text-bottom"><span>Ergebnis:</span></div>
  {#if overviewData.TotalPatient != null}
  <div class="w-full px-4 lg:w-4/12 xl:w-3/12">
    <CardStats
      statSubtitle="Studienteilnehmende"
      statTitle={overviewData?.TotalPatient || 0}
      statIconName="far fa-chart-bar"
      statIconColor="bg-red-600"
      info="Stellt die insgesamte Anzahl der Studienteilnehmenden dar, wobei nur Daten in Bezug zur vorherigen Filter-Auswahl angezeigt werden."
    />
  </div>
  {/if}
  {#if overviewData.TotalBiosamplePatient != null}
  <div class="w-full px-4 lg:w-4/12 xl:w-3/12">
    <CardStats
      statSubtitle="Bioproben"
      statTitle={overviewData?.TotalBiosamplePatient || 0}
      statPercentColor="text-red-500"
      statIconName="fas fa-chart-pie"
      statIconColor="bg-red-600"
      info = "Stellt die insgesamte Anzahl der Bioproben dar, wobei nur Daten in Bezug zur vorherigen Filter-Auswahl angezeigt werden."
    />
  </div>
  {/if}
  {#if overviewData.TotalBiosignalPatient != null}
  <div class="w-full px-4 lg:w-4/12 xl:w-3/12">
    <CardStats
      statSubtitle="Bild- und Biosignaldaten"
      statTitle={overviewData?.TotalBiosignalPatient || 0}
      statPercentColor="text-red-500"
      statIconName="fas fa-chart-pie"
      statIconColor="bg-red-600"
      info = "Stellt die insgesamte Anzahl der Bild- und Biosignaldaten dar, wobei nur Daten in Bezug zur vorherigen Filter-Auswahl angezeigt werden."
    />
  </div>
  {/if}
</div>

<!-- Reactively display content based on the state of data fetching -->
{#if Object.keys(overviewData).length > 0}
  <div>
    <div class="flex flex-wrap">
      {#if overviewData.AgeGroup != null}
      <div class="w-full px-4 xl:w-6/12">
        <CardBarChart_2 {overviewData}/>
      </div>
      {/if}

      {#if overviewData.GenderGroup != null}
      <div class="w-full px-4 xl:w-6/12">
        <CardPieChart {overviewData}/>
      </div>
      {/if}
    </div>
    <div class="flex flex-wrap mt-4">
      {#if overviewData.BioSpecimens != null}
      <div class="w-full px-4 xl:w-6/12">
        <CardBarChart_1 {overviewData}/>
      </div>
      {/if}
      {#if overviewData.ImagesBiosignals != null}
      <div class="w-full px-4 xl:w-6/12">
        <CardBarChart_3 {overviewData}/>
      </div>
      {/if}
    </div>
  </div>
{:else if error}
  <p>Error loading data: {error}</p>
{:else}
  <p>Loading...</p>
{/if}
