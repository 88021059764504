<script>
  import { onMount, onDestroy, createEventDispatcher } from "svelte";
  import Chart from "chart.js";

  export let popupData;
  export let popupTitle;

  let chart;
  const dispatch = createEventDispatcher();

  onMount(() => {
    if (popupData) {
      console.log('Popup data:', popupData);
      initializeChart();
    }
  });

  function initializeChart() {
    const ctx = document.getElementById("popup-chart").getContext("2d");

    if (!ctx) {
      console.error("Canvas context not found");
      return;
    }

    chart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: Object.keys(popupData),
        datasets: [
          {
            label: "Baseline",
            backgroundColor: "#b33528",
            borderColor: "#b33528",
            data: Object.values(popupData),
            fill: false,
            barThickness: 15
          },
          {
            label: "FUP",
            backgroundColor: "rgba(179, 53, 40, 0.6)", // Lighter red
            borderColor: "rgba(179, 53, 40, 0.6)",
            data: [2, 12, 5, 17, 20, 30], // Example FUP data
            fill: false,
            barThickness: 15,
          },
        ]
      },
      options: {
        maintainAspectRatio: false, // Adjust to allow height changes
        responsive: true,
        title: {
          display: true,
          text: popupTitle,
          fontColor: "#4a5568",
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
        legend: {
          labels: {
            fontColor: "rgba(0,0,0,.4)",
          },
          align: "end",
          position: "bottom",
        },
        scales: {
          xAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: "",
            },
            gridLines: {
              borderDash: [2],
              borderDashOffset: [2],
              color: "rgba(33, 37, 41, 0.3)",
              zeroLineColor: "rgba(33, 37, 41, 0.3)",
              zeroLineBorderDash: [2],
              zeroLineBorderDashOffset: [2],
            },
          }],
          yAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: "Anzahl von Proben",
            },
            ticks: {
              beginAtZero: true,
            },
            gridLines: {
              borderDash: [2],
              drawBorder: false,
              borderDashOffset: [2],
              color: "rgba(33, 37, 41, 0.2)",
              zeroLineColor: "rgba(33, 37, 41, 0.15)",
              zeroLineBorderDash: [2],
              zeroLineBorderDashOffset: [2],
            },
          }],
        },
      }
    });
  }

  function handleKeydown(event) {
    if (event.key === 'Escape') {
      close();
    }
  }

  function close() {
    dispatch('close');
  }

  onDestroy(() => {
    if (chart) {
      chart.destroy();
    }
  });

</script>

<style>
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 80%; /* Adjust height to be smaller */
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    overflow: hidden;
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
    z-index: 1001; /* Ensure the close button is above the canvas */
  }

  .chart-container {
    width: calc(100% - 20px);
    height: calc(100% - 20px); /* Adjusted to leave space for the close button */
    padding: 20px;
    box-sizing: border-box;
    z-index: 1000; /* Ensure canvas is below the close button */
  }
</style>

<div class="overlay" on:click={close} on:keydown={handleKeydown}></div>
<div class="popup">
  <button class="close-btn" on:click={close} >&times;</button>
  <div class="chart-container">
    <canvas id="popup-chart"></canvas>
  </div>
</div>

