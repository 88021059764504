<script>
  import { onMount, onDestroy } from "svelte";
  export let infoText;

  let isTooltipVisible = false;
  let infoIconElement;
  let tooltipElement;

  // Toggle the visibility of the tooltip on click
  function toggleTooltip() {
      isTooltipVisible = !isTooltipVisible;
      if (isTooltipVisible) adjustTooltipPosition();
      console.log(infoText);
  }

  // Hide the tooltip if a click happens outside of it
  function handleClickOutside(event) {
      if (
          isTooltipVisible &&
          tooltipElement &&
          infoIconElement &&
          !tooltipElement.contains(event.target) &&
          !infoIconElement.contains(event.target)
      ) {
          isTooltipVisible = false;
      }
  }

  // Adjust the position to ensure tooltip stays within viewport
  function adjustTooltipPosition() {
      const rect = tooltipElement.getBoundingClientRect();
      if (rect.right > window.innerWidth) {
          tooltipElement.style.left = `-${rect.width + 5}px`; // Slightly closer
      } else {
          tooltipElement.style.left = `calc(100% + 5px)`; // Slightly closer to icon
      }
  }

  onMount(() => {
      document.addEventListener("click", handleClickOutside);
  });

  onDestroy(() => {
      document.removeEventListener("click", handleClickOutside);
  });
</script>

<style>
  .info-container {
      position: relative;
      display: inline-block;
      margin-bottom: 6px;
  }

  .info-icon {
      color: #dc2626;
      font-weight: bold;
      font-style: italic;
      font-size: 0.9rem;
      cursor: pointer;
      border-radius: 50%; /* Round shape */
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 1.2rem; /* Smaller width */
      height: 1.2rem; /* Smaller height */
      border: 1px solid #dc2626; /* Red border */
      transition: transform 0.2s;
      background-color: transparent; /* Transparent background */
  }

  .info-icon:hover {
      transform: scale(1.05);
  }

  .tooltip-bubble {
      visibility: hidden;
      background-color: #fff; /* White background */
      color: #333; /* Dark text */
      text-align: left;
      font-weight: bold;
      border-radius: 6px;
      padding: 8px 12px;
      position: absolute;
      z-index: 99999;
      top: -30px;
      left: calc(100% + 5px);
      box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
      opacity: 0;
      transition: opacity 0.2s, transform 0.2s;
      font-size: 0.8rem;
      line-height: 1.2;
      width: 300px; /* Allow stretching */
      max-width: 300px; /* Allow stretching */
      white-space: normal; /* Allow wrapping */
      word-wrap: break-word; /* Ensures long words break */
      border: 1px solid #dc2626; /* Red border */
  }

  /* Removed the arrow from the tooltip */
  .tooltip-visible {
      visibility: visible;
      opacity: 1;
      transform: translateY(-5px);
  }
</style>

<div class="info-container">
  <!-- svelte-ignore a11y-click-events-have-key-events -->
  <span
      class="info-icon"
      aria-label="More information"
      on:click={toggleTooltip}
      bind:this={infoIconElement}
  >
      i
  </span>
  <div
      class="tooltip-bubble {isTooltipVisible ? 'tooltip-visible' : ''}"
      bind:this={tooltipElement}
  >
      {infoText}
  </div>
</div>