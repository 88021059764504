<script>
  export let filters = {};
  export let closePopup;
</script>

<div class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50" on:click={closePopup}>
  <div class="popup-content bg-white rounded shadow-lg max-w-3xl w-full p-4" on:click|stopPropagation>
    <div class="flex justify-between items-center mb-4">
      <h3 class="text-2xl font-bold">All Filters</h3>
      <button class="text-gray-600 hover:text-gray-900" on:click={closePopup}>X</button>
    </div>
    <div class="popup-scroll grid grid-cols-2 gap-4">
      {#each Object.entries(filters) as [key, value]}
        {#if value}
          <div class="px-4 py-2 border bg-white">{key}: {value}</div>
        {/if}
      {/each}
    </div>
    <div class="flex justify-end mt-4">
      <button class="px-4 py-2 bg-red-500 hover:bg-red-700 text-white rounded" on:click={closePopup}>Close</button>
    </div>
  </div>
</div>

<style>
  .fixed {
    position: fixed;
  }
  .inset-0 {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .bg-black {
    background-color: rgba(0, 0, 0, 0.5);
  }
  .max-w-3xl {
    max-width: 768px;
  }
  .popup-content {
    max-height: 80%;
    overflow-y: auto;
  }
  .popup-scroll {
    max-height: 60vh; /* Set the max height of the scrollable content */
    overflow-y: auto;
  }
</style>
