// CardSettingForm.js

// Common options for "ja" and "nein"
const jaNeinOptions = [
  { idPrefix: "ja", namePrefix: "ja", label: "ja" },
  { idPrefix: "nein", namePrefix: "nein", label: "nein" },
];

// Sample data structure for form fields
export const formDataStructure = [
  {
    section: "KLINISCHE DATEN",
    subsections: [
      {
        title: "Körperliche Untersuchung und soziodemographische Angaben",
        fields: [
          {
            type: "checkbox-group",
            label: "Geschlecht",
            options: [
              { id: "male", name: "geschlecht_male", label: "männlich" },
              { id: "female", name: "geschlecht_female", label: "weiblich" },
              { id: "divers", name: "geschlecht_divers", label: "divers" },
            ],
          },
          { type: "checkbox", id: "geburtsdatum", name: "geburtsdatum", label: "Geburtsdatum", inputs: [{ type: "date" }, { type: "date" }] },
          { type: "checkbox", id: "alter", name: "alter", label: "Alter" },
          { type: "checkbox", id: "groesse", name: "groesse", label: "Größe", inputs: [{ type: "number", unit: "cm" }, { type: "number", unit: "cm" }] },
          { type: "checkbox", id: "gewicht", name: "gewicht", label: "Gewicht", inputs: [{ type: "number", unit: "kg" }, { type: "number", unit: "kg" }] },
          { type: "checkbox", id: "bmi", name: "bmi", label: "BMI" },
          {
            type: "checkbox-group",
            label: "Familiäre Disposition von Myokardinfarkt oder Schlaganfall",
            options: jaNeinOptions.map(option => ({
              id: `fam_${option.idPrefix}`,
              name: `fam_disposition_${option.namePrefix}`,
              label: option.label,
            })),
          },
        ],
      },
      {
        title: "Kardiovaskuläre Risikofaktoren",
        fields: [
          {
            type: "checkbox-group",
            label: "Diabetes mellitus",
            options: jaNeinOptions.map(option => ({
              id: `diabetes_${option.idPrefix}`,
              name: `diabetes_${option.namePrefix}`,
              label: option.label,
            })),
          },
          {
            type: "checkbox-group",
            label: "Arterielle Hypertonie",
            options: jaNeinOptions.map(option => ({
              id: `hypertonie_${option.idPrefix}`,
              name: `hypertonie_${option.namePrefix}`,
              label: option.label,
            })),
          },
          {
            type: "checkbox-group",
            label: "Dyslipidämie",
            options: jaNeinOptions.map(option => ({
              id: `dyslipidamie_${option.idPrefix}`,
              name: `dyslipidamie_${option.namePrefix}`,
              label: option.label,
            })),
          },
          {
            type: "checkbox-group",
            label: "Raucher",
            options: [
              { id: "raucher_ja", name: "raucher_ja", label: "ja" },
              { id: "raucher_nein", name: "raucher_nein", label: "nein" },
              { id: "ex_raucher", name: "ex_raucher", label: "Ex-Raucher (>= 6 Mon. clean)" },
            ],
          },
          { type: "checkbox", id: "ex_raucher_seit", name: "ex_raucher_seit", label: "Ex-Raucher seit", inputs: [{ type: "number" }]  },
          {
            type: "checkbox-group",
            label: "Dialysepflicht",
            options: jaNeinOptions.map(option => ({
              id: `dialyse_${option.idPrefix}`,
              name: `dialyse_${option.namePrefix}`,
              label: option.label,
            })),
          },
        ],
      },
      {
        title: "Kardiale Diagnosen (Anamnese und Vorbefunde)",
        fields: [
          {
            type: "checkbox-group",
            label: "Koronare Herzkrankheit",
            options: jaNeinOptions.map(option => ({
              id: `koronare_${option.idPrefix}`,
              name: `koronare_${option.namePrefix}`,
              label: option.label,
            })),
          },
          {
            type: "checkbox-group",
            label: "Zustand nach Myokardinfarkt",
            options: jaNeinOptions.map(option => ({
              id: `myokardinfarkt_${option.idPrefix}`,
              name: `myokardinfarkt_${option.namePrefix}`,
              label: option.label,
            })),
          },
          {
            type: "checkbox-group",
            label: "Kardiomyopathie",
            options: jaNeinOptions.map(option => ({
              id: `kardiomyopathie_${option.idPrefix}`,
              name: `kardiomyopathie_${option.namePrefix}`,
              label: option.label,
            })),
          },
          {
            type: "checkbox-group",
            label: "Herzinsuffizienz",
            options: jaNeinOptions.map(option => ({
              id: `herzinsuffizienz_${option.idPrefix}`,
              name: `herzinsuffizienz_${option.namePrefix}`,
              label: option.label,
            })),
          },
          {
            type: "checkbox-group",
            label: "einem Arzt diagnostizierte aktuelle oder frühere Herzklappenerkrankung",
            options: jaNeinOptions.map(option => ({
              id: `herzklappenerkrankung_${option.idPrefix}`,
              name: `herzklappenerkrankung_${option.namePrefix}`,
              label: option.label,
            })),
          },
          {
            type: "checkbox-group",
            label: "einem Arzt diagnostizierter angeborener Herzfehler",
            options: jaNeinOptions.map(option => ({
              id: `angeborener_herzfehler_${option.idPrefix}`,
              name: `angeborener_herzfehler_${option.namePrefix}`,
              label: option.label,
            })),
          },
        ],
      },
      {
        title: "Bisherige kardiovaskuläre Interventionen",
        fields: [
          {
            type: "checkbox-group",
            label: "Interventionelle koronare Revaskularisation",
            options: jaNeinOptions.map(option => ({
              id: `revaskularisation_${option.idPrefix}`,
              name: `revaskularisation_${option.namePrefix}`,
              label: option.label,
            })),
          },
          {
            type: "checkbox-group",
            label: "Herzklappen-Operation",
            options: jaNeinOptions.map(option => ({
              id: `herzklappen_operation_${option.idPrefix}`,
              name: `herzklappen_operation_${option.namePrefix}`,
              label: option.label,
            })),
          },
          {
            type: "checkbox-group",
            label: "Implantierter Herzschrittmacher oder Defibrillator",
            options: jaNeinOptions.map(option => ({
              id: `herzschrittmacher_${option.idPrefix}`,
              name: `herzschrittmacher_${option.namePrefix}`,
              label: option.label,
            })),
          },
        ],
      },
      {
        title: "Aktuelle Nebendiagnose",
        fields: [
          {
            type: "checkbox-group",
            label: "pAVK",
            options: jaNeinOptions.map(option => ({
              id: `pavk_${option.idPrefix}`,
              name: `pavk_${option.namePrefix}`,
              label: option.label,
            })),
          },
          {
            type: "checkbox-group",
            label: "Schlaganfall/TIA",
            options: jaNeinOptions.map(option => ({
              id: `schlaganfall_${option.idPrefix}`,
              name: `schlaganfall_${option.namePrefix}`,
              label: option.label,
            })),
          },
          {
            type: "checkbox-group",
            label: "Chronische Lungenerkrankung",
            options: jaNeinOptions.map(option => ({
              id: `lungenerkrankung_${option.idPrefix}`,
              name: `lungenerkrankung_${option.namePrefix}`,
              label: option.label,
            })),
          },
          {
            type: "checkbox-group",
            label: "Depression",
            options: jaNeinOptions.map(option => ({
              id: `depression_${option.idPrefix}`,
              name: `depression_${option.namePrefix}`,
              label: option.label,
            })),
          },
          {
            type: "checkbox-group",
            label: "Krebserkrankungen von mehr als 5 Jahren",
            options: jaNeinOptions.map(option => ({
              id: `krebserkrankung_5plus_${option.idPrefix}`,
              name: `krebserkrankung_5plus_${option.namePrefix}`,
              label: option.label,
            })),
          },
          {
            type: "checkbox-group",
            label: "Krebserkrankungen von weniger als 5 Jahren",
            options: jaNeinOptions.map(option => ({
              id: `krebserkrankung_5minus_${option.idPrefix}`,
              name: `krebserkrankung_5minus_${option.namePrefix}`,
              label: option.label,
            })),
          },
        ],
      },
      {
        title: "Blutdruck nach 5 Minuten Ruhe",
        fields: [
          { type: "checkbox", id: "systolisch", name: "systolisch", label: "Systolisch", inputs: [{ type: "number", unit: "mmHg" }, { type: "number", unit: "mmHg" }] },
          { type: "checkbox", id: "diastolisch", name: "diastolisch", label: "Diastolisch", inputs: [{ type: "number", unit: "mmHg" }, { type: "number", unit: "mmHg" }] },
        ],
      },
      {
        title: "Herzfrequenz nach 5 Minuten Sitzen",
        fields: [
          { type: "checkbox", id: "herzfrequenz", name: "herzfrequenz", label: "Herzfrequenz nach 5 Minuten Sitzen", inputs: [{ type: "number", unit: "cm" }, { type: "number", unit: "bpm" }] },
        ],
      },
      {
        title: "Labordiagnostik Blut",
        fields: [
          { type: "checkbox", id: "blutentnahme", name: "blutentnahme", label: "Datum der Blutentnahme", inputs: [{ type: "date" }, { type: "date" }] },
          { type: "checkbox", id: "blutabnahme_baseline", name: "blutabnahme_baseline", label: "Abstand der Blutabnahme zur Baseline in Wochen" },
          { type: "checkbox", id: "haemoglobin", name: "haemoglobin", label: "Hämoglobin", inputs: [{ type: "number", unit: "g/dl" }, { type: "number", unit: "g/dl" }] },
          { type: "checkbox", id: "kreatinin", name: "kreatinin", label: "Kreatinin (Serum, Heparin-Plasma)", inputs: [{ type: "number", unit: "mg/dl" }, { type: "number", unit: "mg/dl" }] },
          { type: "checkbox", id: "gesamtcholesterol", name: "gesamtcholesterol", label: "Gesamtcholesterol", inputs: [{ type: "number", unit: "mg/dl" }, { type: "number", unit: "mg/dl" }] },
        ],
      },
      {
        title: "Frauenspezifische anamnestische Fragen",
        fields: [
          {
            type: "checkbox-group",
            label: "Menopause",
            options: jaNeinOptions.map(option => ({
              id: `menopause_${option.idPrefix}`,
              name: `menopause_${option.namePrefix}`,
              label: option.label,
            })),
          },
          { type: "checkbox", id: "jahr_der_menopause", name: "jahr_der_menopause", label: "Jahr der Menopause", inputs: [{ type: "number"}, { type: "number"}] },
          { type: "checkbox", id: "letzte_regelblutung", name: "letzte_regelblutung", label: "Tag des Beginns der letzten Regelblutung", inputs: [{ type: "date" }, { type: "date" }] },
        ],
      },
    ],
  },
  {
    section: "BIOPROBEN",
    subsections: [
      {
        title: "Bioprobenart",
        fields: [
          {
            type: "checkbox-group",
            label: "Baseline" ,
            options : [
              { type: "checkbox", id: "serum_baseline", name: "serum_baseline", label: "Serum" },
              { type: "checkbox", id: "edta_plasma_baseline", name: "edta_plasma_baseline", label: "EDTA Plasma" },
              { type: "checkbox", id: "citratplasma_baseline", name: "citratplasma_baseline", label: "Citratplasma" },
              { type: "checkbox", id: "urin_baseline", name: "urin_baseline", label: "Urin" },
              { type: "checkbox", id: "edta_voll_baseline", name: "edta_voll_baseline", label: "EDTA Vollblut" },
              { type: "checkbox", id: "paxgene_baseline", name: "paxgene_baseline", label: "PAXgene" },
              { type: "checkbox", id: "gewebe_baseline", name: "gewebe_baseline", label: "Gewebe schockgefroren" },
            ],
          },
          {
            type: "checkbox-group",
            label: "FUP" ,
            options : [
              { type: "checkbox", id: "serum_fup", name: "serum_fup", label: "Serum" },
              { type: "checkbox", id: "edta_plasma_fup", name: "edta_plasma_fup", label: "EDTA Plasma" },
              { type: "checkbox", id: "citratplasma_fup", name: "citratplasma_fup", label: "Citratplasma" },
              { type: "checkbox", id: "urin_fup", name: "urin_fup", label: "Urin" },
              { type: "checkbox", id: "edta_voll_fup", name: "edta_voll_fup", label: "EDTA Vollblut" },
              { type: "checkbox", id: "paxgene_fup", name: "paxgene_fup", label: "PAXgene" },
              { type: "checkbox", id: "gewebe_fup", name: "gewebe_fup", label: "Gewebe schockgefroren" },
            ],
          },
        ],
      },
    ],
  },
  {
    section: "BILD- & BIOSIGNALDATEN",
    subsections: [
      {
        title: "Art der BILD- & BIOSIGNALDATEN",
        fields: [
          {
            type: "big-checkbox-group",
            label: "Baseline" ,
            options: [
              {
                type: "checkbox-group",
                label: "Echokardiographie (Echo)",
                options: [
                  { id: "echo_tte_baseline", name: "echo_tte_baseline", label: "sicher TTE" },
                  { id: "echo_tee_baseline", name: "echo_tee_baseline", label: "sicher TEE" },
                ],
              },
              {
                type: "checkbox-group",
                label: "Magnetresonanztherapie",
                options: [
                  { id: "magnetresonanz_kontrastmittel_baseline", name: "magnetresonanz_kontrastmittel_baseline", label: "sicher Kontrastmittel" },
                ],
              },
              {
                type: "checkbox-group",
                label: "Volumen",
                options: [
                  { id: "volumen_3d_baseline", name: "volumen_3d_baseline", label: "3D" },
                  { id: "volumen_2d_baseline", name: "volumen_2d_baseline", label: "2D" },
                ],
              },
            ],
          },
          {
            type: "big-checkbox-group",
            label: "FUP" ,
            options: [
              {
                type: "checkbox-group",
                label: "Echokardiographie (Echo)",
                options: [
                  { id: "echo_tte_fup", name: "echo_tte_fup", label: "sicher TTE" },
                  { id: "echo_tee_fup", name: "echo_tee_fup", label: "sicher TEE" },
                ],
              },
              {
                type: "checkbox-group",
                label: "Magnetresonanztherapie",
                options: [
                  { id: "magnetresonanz_kontrastmittel_fup", name: "magnetresonanz_kontrastmittel_fup", label: "sicher Kontrastmittel" },
                ],
              },
              {
                type: "checkbox-group",
                label: "Volumen",
                options: [
                  { id: "volumen_3d_fup", name: "volumen_3d_fup", label: "3D" },
                  { id: "volumen_2d_fup", name: "volumen_2d_fup", label: "2D" },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

