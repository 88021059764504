<script>
  import CheckboxGroup from './DataSettingsElements/CheckboxGroup.svelte';
  import InputField from './DataSettingsElements/InputField.svelte';
  import { formDataStructure } from './DataSettingsElements/CardSettingForm.js';
  import { navigate } from 'svelte-routing';
  import { isLoading, showSavePopup, currentPage } from '../../store.ts'; // Import the store
  import SavePopup from "../Graphs/SavePopup.svelte"; // Import SavePopup component
  import { onMount } from 'svelte';
  import InfoBubble from '../Graphs/InfoBubble.svelte';

  const API_URL = process.env.API_URL;
  let savedFilters = {}; // To store the filters retrieved from the session
  // State for the switch
  let useMapping = true;

  // Function to submit form data
  async function SubmitFormData() {

    if (validateForm()) {
      console.log('Form is valid. Proceed with submission.');
      isLoading.set(true); // Start loading
      // Call your form submission logic here
    try {
      // Use the extracted function to collect form data
      const formDataObj = collectFormData(formDataStructure);

      const requestData = {
        data: formDataObj,
        useMapping: useMapping
      };

      const response = await fetchWithTimeout(`${API_URL}/submit_form`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(requestData),
      }, 180000); // 5 seconds timeout

      console.log(response);
      const data = await response.json();

      // Check if the data.status is 'success' and if data.data exists and is not empty
      if (!Object.values(data).every(value => value === null)) {
        currentPage.set('results');
        navigate('/admin/results');
      } else {
        alert('Zu Ihrer Suchanfrage konnten keine Studienteilnehmenden gefunden werden. Entfernen Sie entsprechende Filter oder setzen Sie Ihre Suche mit weiteren Filtern fort.');
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        alert('Request timed out.');
      } else {
        console.error('Error submitting form:', error);
      }
    } finally {
      isLoading.set(false); // End loading
    }
    } else {
      alert('Some fields have invalid values. Please correct them.');
    }

  }

// Modified saveQuery to receive title and comment
async function saveQuery({ detail }) {
  const { title, comment } = detail; // Get title and comment from event
  if (!title) {
    alert("Bitte geben Sie einen Titel ein.");
    return;
  }

  isLoading.set(true); // Start loading

  const formDataObj = collectFormData(formDataStructure);

  const requestData = {
    data: formDataObj,
    title: title,
    comment: comment,
    useMapping: useMapping
  };

  try {
    const response = await fetch(`${API_URL}/save_query`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      credentials: 'include',
      body: JSON.stringify(requestData),
    }, 180000);

    const data = await response.json();
    console.log(data);

    if (data.message === 'SUCCESS') {
      showSavePopup.set(false);
      alert('Ihre Abfrage wurde erfolgreich gespeichert.');
    } else if (data.message === 'EMPTY_DATA') {
      alert('Zu Ihrer Suchanfrage konnten keine Studienteilnehmenden gefunden werden. Entfernen Sie entsprechende Filter oder setzen Sie Ihre Suche mit weiteren Filtern fort.');
    }
    // No alert for other cases
  } catch (error) {
    if (error.name === 'AbortError') {
      console.error('Request timed out:', error);
    } else {
      console.error('Error saving query:', error);
    }
  } finally {
    isLoading.set(false); // End loading
  }
}


// Utility function to add timeout to fetch requests
async function fetchWithTimeout(resource, options = {}, timeout = 0) {
  const controller = new AbortController();
  
  // No timeout if timeout is set to 0
  let id;
  if (timeout > 0) {
    id = setTimeout(() => controller.abort(), timeout);
  }

  const response = await fetch(resource, {
    ...options,
    signal: controller.signal
  });

  if (id) clearTimeout(id); // Clear the timeout if it was set
  return response;
}


  // Function to open the save popup
  function openSavePopup() {
    showSavePopup.set(true);
  }

  // Function to close the save popup
  function closeSavePopup() {
    showSavePopup.set(false);
  }

  // Handle keydown events for accessibility
  function handleKeydown(event) {
    if (event.key === 'Enter' || event.key === ' ') {
      if (event.currentTarget === document.getElementById('openSavePopupButton')) {
        openSavePopup();
      } else if (event.currentTarget === document.getElementById('startLoadingButton')) {
        SubmitFormData();
      } else if (event.currentTarget === document.querySelector('.overlay')) {
        closeSavePopup();
      }
    }
  }

// Collect form data, including dropdown values
function collectFormData(formDataStructure) {
  const formDataObj = {};

  formDataStructure.forEach(section => {
    // Initialize each section as an empty object in formDataObj
    if (!formDataObj[section.section]) {
      formDataObj[section.section] = {};
    }

    section.subsections.forEach(subsection => {
      subsection.fields.forEach(field => {
        if (field.type === 'checkbox-group') {
          // Handle checkbox group fields
          field.options.forEach(option => {
            const element = document.getElementById(option.id);
            formDataObj[section.section][option.name] = element ? element.checked : null;
          });
        } else {
          // Handle regular checkboxes or other input types
          const element = document.getElementById(field.id);
          formDataObj[section.section][field.name] = element ? element.checked : null;

          if (field.inputs) {
            const inputElement0 = document.getElementById(`${field.id}-0`);
            const inputElement1 = field.inputs.length === 2 ? document.getElementById(`${field.id}-1`) : null;
            const dropdownElement = document.getElementById(`${field.id}-unit`);

            if (field.inputs.length === 2) {
              if (element.checked){
                if (field.inputs[0].type === "number") {
                // Handle number inputs with two fields
                formDataObj[section.section][`${field.name}-0`] = inputElement0.value !== "" ? inputElement0.value : String(field.ranges[0]);
                formDataObj[section.section][`${field.name}-1`] = inputElement1.value !== "" ? inputElement1.value : String(field.ranges[1]);
                // Handle dropdowns
                if (dropdownElement) {
                  formDataObj[section.section][`${field.name}-unit`] = dropdownElement.value;
                }
                } else {
                // Handle other types with two fields
                formDataObj[section.section][`${field.name}-0`] = inputElement0.value !== "" ? inputElement0.value : field.ranges[0];
                const today = new Date();
                const formattedDate = today.toISOString().split('T')[0];
                formDataObj[section.section][`${field.name}-1`] = inputElement1.value !== "" ? inputElement1.value : `${formattedDate}`;
                }
              } else if (inputElement0.value != "" || inputElement1.value != "") {
                formDataObj[section.section][`${field.name}-0`] = inputElement0.value !== "" ? inputElement0.value : String(field.ranges[0]);
                formDataObj[section.section][`${field.name}-1`] = inputElement1.value !== "" ? inputElement1.value : String(field.ranges[1]);
                // Handle dropdowns
                if (dropdownElement) {
                  formDataObj[section.section][`${field.name}-unit`] = dropdownElement.value;
                }
              }

            } else if (field.inputs.length === 1 && element.checked && field.name !== "ex_raucher_seit") {
              // Handle single-field inputs
              formDataObj[section.section][`${field.name}-0`] = inputElement0.value !== "" ? inputElement0.value : String(field.ranges[0]);
            }
          }
        }
      });
    });
  });

  console.log(formDataObj); // Debugging purpose
  return formDataObj;
}

  // Clear all fields in the form
  function clearForm() {
    formDataStructure.forEach((_, index) => {
      clearSection(`section-${index}`);
    });
  }

  // Clear fields within a specific section
  function clearSection(sectionId) {
    const section = document.getElementById(sectionId);
    console.log(section);
    if (section) {
      const inputs = section.querySelectorAll('input');
      inputs.forEach(input => {
        if (input.type === 'checkbox' || input.type === 'radio') {
          input.checked = false;
        } else {
          input.value = '';
        }
      });
    }
  }

  // Fill the section with default values
  function fillSection(sectionId) {
    const section = document.getElementById(sectionId);
    if (section) {
      const inputs = section.querySelectorAll('input');
      inputs.forEach(input => {
        switch (input.type) {
          case 'checkbox':
            input.checked = true;
            break;
          default:
            input.value = '';  // Set other input types to empty if needed
        }
      });
    }
  }

  // Determine the number of columns based on the number of fields
  function determineColumns(fieldsLength) {
    if (fieldsLength % 3 === 0) {
      return 3; // Default and preferred division by 3
    } else if (fieldsLength % 2 === 0) {
      return 2; // If not divisible by 3 but divisible by 2
    }
    return 3; // If neither, default to 3 (for cases like 5 or 7 elements)
  }

  // Function to handle toggle change
  function toggleMapping() {
    useMapping = !useMapping;
    console.log("Use Mapping:", useMapping ? "ON" : "OFF")
  }
  
  // Function to fill form data based on saved filters
  function fillFormWithSavedData(savedData, formDataStructure) {
  // Loop through the formDataStructure and match with saved data
  formDataStructure.forEach(section => {
    if (savedData[section.section]) {
      const sectionData = savedData[section.section];

      section.subsections.forEach(subsection => {
        subsection.fields.forEach(field => {
          // Fill checkbox-group fields
          if (field.type === 'checkbox-group') {
            field.options.forEach(option => {
              const element = document.getElementById(option.id);
              if (element && sectionData[option.name] !== undefined) {
                element.checked = sectionData[option.name]; // Set checkbox value
              }
            });
          } else {
            const element = document.getElementById(field.id);
            if (element && sectionData[field.name] !== undefined) {
              // Handle simple checkbox or input fields
              element.checked = sectionData[field.name];

              if (field.inputs) {
                field.inputs.forEach((_, index) => {
                  const inputElement = document.getElementById(`${field.id}-${index}`);
                  if (inputElement && sectionData[`${field.name}-${index}`] !== undefined) {
                    inputElement.value = sectionData[`${field.name}-${index}`];
                  }
                });
              }
            }
          }
        });
      });
    }
  });
  }

    // Fetch session data and pre-fill the form if necessary
    async function fetchSessionData() {
    try {
      const response = await fetch(`${API_URL}/get_session_data`, {
        method: 'GET',
        credentials: 'include', // Include cookies
      });

      if (!response.ok) {
        currentPage.set('search');
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const resultbff = await response.json();
      const result = resultbff.filters;
      console.log("This is the data from the session: ", result);

      if (result) {
        // Access the filterCriteria directly
        savedFilters = result.nestedFEData || {};
        console.log("Filter Criteria:", savedFilters);

        // Parse the resultData since it is a stringified JSON
        let parsedResultData;
        if (typeof result.resultData === 'string') {
          parsedResultData = JSON.parse(result.resultData);
        } else {
          parsedResultData = result.resultData;
        }

        // Use parsedResultData directly for the charts
        let overviewData = parsedResultData || {};
        console.log("Parsed Overview Data:", overviewData);

        // Call function to populate the form with saved filters
        if (Object.keys(savedFilters).length > 0) {
          fillFormWithSavedData(savedFilters, formDataStructure); // Populate form
        }
      } else {
        console.log(result.message);
        throw new Error(result.message || 'Unknown error occurred');
      }
    } catch (err) {
      let error = `Error fetching form data: ${err.message}`;
      console.error('Error fetching form data:', error);
    }
  }

  // Use onMount to trigger the session data fetch when the component is mounted
  onMount(() => {
    fetchSessionData(); // Fetch session data on component mount
  });

    // Helper function to determine if a section has values
    function hasSectionValues(sectionData) {
    if (!sectionData) {
      return false; // No section data available, return false
    }

    return Object.values(sectionData).some(value => value !== false && value !== "" && value !== null);
  }

  let validationErrors = []; // Store validation errors

function validateForm() {
  validationErrors = []; // Clear previous errors

  formDataStructure.forEach(section => {
    section.subsections.forEach(subsection => {
      subsection.fields.forEach(field => {
        if (field.inputs && field.ranges) {
          field.inputs.forEach((_, index) => {
            const inputElement = document.getElementById(`${field.id}-${index}`);
            if (inputElement) {
              const value = parseFloat(inputElement.value);
              const min = field.ranges[0];
              const max = field.ranges[1];

              if (!isNaN(value) && (value < min || value > max)) {
                validationErrors.push({
                  id: inputElement.id,
                  field: field.label,
                  value,
                  min,
                  max,
                  message: `Value ${value} is out of range (${min} - ${max}).`
                });
              }
            }
          });
        }
      });
    });
  });

  if (validationErrors.length > 0) {
    console.log('Validation errors:', validationErrors);
    return false; // Validation failed
  }

  return true; // Validation passed
}

</script>

<h1 class="text-red-600 text-6xl font-bold flex items-center justify-center h-full" style="font-size: 2rem;">
  Durchsuchen Sie unsere Daten
</h1>

<div class="button-container flex justify-between items-center p-4 space-x-4 flex-wrap">
  <div class="flex items-center space-x-2">
    {#if false}
      <!-- Custom Toggle Switch -->
      <label class="toggle-switch">
        <input type="checkbox" bind:checked={useMapping} on:click={toggleMapping} />
        <span class="slider"></span>
      </label>
      <!-- Add Mapping Text -->
      <span class="text-sm font-semibold">Mapping aktivieren</span>
    {/if}
  </div>

  <!-- Existing Buttons -->
  <div class="flex justify-end space-x-4 button-group">
    <button class="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-700 active:bg-red-800" disabled="{$isLoading}" id="startLoadingButton" on:click="{SubmitFormData}" on:keydown="{handleKeydown}" type="button">
      {#if $isLoading}
        <div class="spinner"></div>
      {:else}
        Abfrage starten
      {/if}
    </button>
    <button class="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-700 active:bg-red-800" id="clearFormButton" on:click="{clearForm}" type="button">
      Abfrage zurücksetzen
    </button>
    <button class="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-700 active:bg-red-800" id="openSavePopupButton" on:click="{openSavePopup}" on:keydown="{handleKeydown}" type="button">
      Abfrage speichern
    </button>
  </div>
</div>

<form on:submit|preventDefault>
  {#each formDataStructure as section, sectionIndex}
  <details class="section" id={`section-${sectionIndex}`} open={hasSectionValues(savedFilters[section.section])}>
    <summary class="section-title">
      {section.section}
    </summary>
    {#each section.subsections as subsection}
      <div class="subsection">
        <h3 class="subsection-title">
          {subsection.title}
          {#if subsection.info != undefined}
          <InfoBubble infoText={subsection.info} class="ml-2" />
          {/if}
        </h3>
        <table class="subsection-table">
          <thead>
            <tr>
              <th colspan="{determineColumns(subsection.fields.length)}"></th>
            </tr>
          </thead>
          <tbody>
            {#each subsection.fields as field, i}
              {#if i % determineColumns(subsection.fields.length) === 0}
                <tr>
              {/if}
              <td class="field-cell">
                {#if field.type === 'checkbox-group'}
                  <CheckboxGroup label={field.label} options={field.options} info={field.info}/>
                {:else if field.type === 'checkbox'}
                  <InputField id={field.id} name={field.name} label={field.label} 
                  info={field.info} inputs={field.inputs || []} ranges={field.ranges}/>
                {/if}
              </td>
            {/each}
          </tbody>
        </table>
      </div>
    {/each}
    <div class="flex justify-end p-4 space-x-4">
      <button class="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-700 active:bg-red-800" on:click={() => fillSection(`section-${sectionIndex}`)}>
        Alle auswählen
      </button>
      <button class="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-700 active:bg-red-800" on:click={() => clearSection(`section-${sectionIndex}`)}>
        Zurücksetzen
      </button>
    </div>
  </details>
{/each}
</form>

{#if $showSavePopup}
  <div class="overlay" on:click={closeSavePopup} on:keydown>
    <SavePopup on:close="{closeSavePopup}" on:save={saveQuery} />
  </div>
{/if}

<style>
  .section {
    background-color: rgba(228, 228, 231, 1);
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 1rem;
    padding: 1rem;
  }
  .section-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    cursor: pointer;
  }
  .subsection {
    margin-bottom: 1rem;
  }
  .subsection-title {
    font-size: 1.25rem;
    font-weight: bold;
    color: #373737;
    margin-bottom: 0.5rem;
  }
  .subsection-table {
    width: 100%;
    margin-bottom: 1rem;
    margin-left: 0rem;
    border-collapse: collapse;
  }
  .field-cell {
    padding: 0.5rem;
    border: 1px solid #ddd;
    background-color: #e4ebf2;
  }
  .subsection-table tr:nth-child(odd) .field-cell {
    background-color: #e4ebf2;
  }
  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9998;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 30px;
  }

  .toggle-switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    background-color: #b33528;
  }

  input:checked + .slider:before {
    transform: translateX(26px);
  }

  /* Responsive table fields */
  .subsection-table {
    table-layout: auto;
    width: 100%;
  }
  @media screen and (max-width: 768px) {
    .subsection-table td {
      display: block;
      width: 100%;
    }
  }
</style>