<script>
    import { link } from "svelte-routing";
</script>

<div
  class="relative flex flex-col w-full min-w-0 mb-6 break-words border-0 rounded-lg shadow-lg bg-blueGray-100"
>
  <div class="px-6 py-3 mb-0 bg-white border-4 rounded-t">
    <div class="flex justify-between pb-2 text-center">
      <h6 class="text-red-600 text-1xl font-semi">Hilfe/Support</h6>
    </div>
    <div class="flex-auto px-0 py-0 pt-0 lg:px-2">
      <form>
        <div class="p-4 m-2 bg-gray-200 rounded-lg shadow-md">
          <h3><strong><u>Benutzerhandbuch:</u> </strong></h3>
          <p class="pt-1 pb-2" style="text-align: justify;">
          Der Feasibility Explorer ist das Werkzeug, der es den Forscher:innen ermöglicht,
          sich einen Überblick über die für Nutzungsprojekte freigegebene Daten und
          Bioproben der DZHK-Sammlung zu verschaffen.
          Der Feasibility Explorer unterstützt Forscher:innen weiterhin bei der Filterung
          von Daten und Bioproben für ein bestimmtes Kollektiv, das ihr individuelles
          Forschungsprojekt beantworten kann. Ausgewählte Filtereinstellungen können
          direkt in den Nutzungsantrag übernommen werden. Es werden ausschließlich
          bereinigte Daten von Teilnehmer:innen mit gültiger Einwilligung verwendet.
          <h3><a href="#Startseite">Startseite</a></h3>
          <h3><a href="#Suche">Suche</a></h3>
          <h3><a href="#Datenabruf">Datenabruf</a></h3>
          <h3><a href="#Antragstellung">Antragstellung</a></h3>
        </div>
      </form>
        <form>
          <div class="p-4 m-2 bg-gray-200 rounded-lg shadow-md">
            <h3><strong><u>Antragstellung</u></strong></h3>
            <p class="pt-1 pb-2" style="text-align: justify;">
        Die DZHK Heart Bank beinhaltet umfangreiche kardiovaskuläre Ressourcen.
        Diese umfassen qualitativ hochwertige klinische Daten, Bilddaten, OMICs
        Daten sowie Flüssig- und Gewebeproben inkl. dazugehöriger Daten deren Verarbeitung
        und Lagerung zuverlässig und unter standardisierten Bedingungen erfolgt.
        Wie Sie sich eine passende Auswahl an Daten und Bioproben aus der DZHK
        Heart Bank für Ihr Forschungsprojekt zusammenstellen und wie diese dann
        zu beantragen sind, erklären wir Ihnen
        <strong><a
          use:link
          href="https://dzhk.de/dzhk-heart-bank/antragstellung/"
        >
          hier.
        </a></strong></p>
      </form>
    </div>
  </div>
</div>
