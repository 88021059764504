<script>
  import InfoBubble from '../../Graphs/InfoBubble.svelte';
  export let id;
  export let name;
  export let label;
  export let info;
  export let inputs = [];
  export let ranges;
</script>

<div class="mb-4">
  <div class="flex items-center">
    <label for={id} class="block mb-1 font-semibold">
      <input id={id} name={name} type="checkbox" />
      {label}
    </label>
    <!-- Use the InfoBubble component -->
     {#if info != undefined}
    <InfoBubble infoText={info} />
    {/if}
  </div>

  <div class="flex space-x-2 items-center">
    {#each inputs as input, index}
      <div class="flex items-center">
        {#if id == "bmi"}
        <input 
        id={id + '-' + index} 
        type={input.type} 
        class="input-field border px-2 py-1 rounded" 
        min={ranges[0]} 
        max={ranges[1]} 
        step="0.1"
        />    
        {:else}
        <input 
        id={id + '-' + index} 
        type={input.type} 
        class="input-field border px-2 py-1 rounded" 
        min={ranges[0]} 
        max={ranges[1]} 
        />   
        {/if}   
        {#if index < inputs.length - 1}
          <span class="bis-text">bis</span>
        {/if}
      </div>
    {/each}
    {#if inputs.length && inputs[inputs.length - 1].unit && Array.isArray(inputs[inputs.length - 1].unit)}
      <!-- Added id for the dropdown so it can be accessed later -->
      <select id={id + '-unit'} class="unit-dropdown">
        {#each inputs[inputs.length - 1].unit as unit}
          <option value={unit}>{unit}</option>
        {/each}
      </select>
    {:else if inputs.length && inputs[inputs.length - 1].unit}
      <span class="unit-text">{inputs[inputs.length - 1].unit}</span>
    {/if}
  </div>
</div>

<style>
  .input-field {
    width: 150px; /* Ensures consistent width */
    max-width: 250px; /* Set a max width */
    margin-right: 0.5rem;
    padding: 0.5rem 0.8rem;
    border: 1px solid #ccc; 
    border-radius: 0.25rem;
    font-size: 1rem;
    background-color: rgba(255, 255, 255, 0.9); 
    box-sizing: border-box; /* Includes padding and border in the width calculation */
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
    color: #333;
  }

  .input-field:focus {
    outline: none;
    border-color: #b91c1c;
    background-color: rgba(255, 255, 255, 1); 
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  }

  .bis-text {
    margin: 0 0.5rem;
  }

  /* Adjust the margin-left to reduce distance between input and unit */
  .unit-text {
    margin-left: 0.1rem; /* Reduced from 0.5rem to 0.1rem */
  }

  .unit-dropdown {
    margin-left: 0.1rem; /* Reduced from 0.5rem to 0.1rem */
    padding: 0.4rem 0.8rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    background-color: rgba(255, 255, 255, 0.9);
    font-size: 1rem;
    color: #222;
    width: 160px;
    transition: box-shadow 0.3s ease, border-color 0.3s ease;
  }

  .unit-dropdown:hover {
    border-color: #b91c1c;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  .unit-dropdown:focus {
    outline: none;
    border-color: #b91c1c;
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.15);
  }

  .flex.items-center > * {
    margin-right: 0.5rem;
  }
  input:invalid {
    /* Reset the default invalid style applied by browsers */
    box-shadow: none;
    background-color: #ffe6e6; /* Light red background */
  }

  input:valid {
    /* Ensure consistent valid styling */
    box-shadow: none;
  }

</style>