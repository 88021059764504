<script>
    export let label;
    export let options;
    import CheckboxGroup from "./CheckboxGroup.svelte";
    import InputField from "./InputField.svelte";
  
    let allChecked = false;
    let noneChecked = true;
  
    function updateCheckStates() {
      allChecked = options.every(option => {
        if (option.type === 'checkbox-group') {
          return option.options.every(subOption => document.getElementById(subOption.id)?.checked);
        } else {
          return document.getElementById(option.id)?.checked;
        }
      });
      noneChecked = options.every(option => {
        if (option.type === 'checkbox-group') {
          return option.options.every(subOption => !document.getElementById(subOption.id)?.checked);
        } else {
          return !document.getElementById(option.id)?.checked;
        }
      });
    }
  
    function toggleAll() {
      const newCheckedState = !allChecked;
      options.forEach(option => {
        if (option.type === 'checkbox-group') {
          option.options.forEach(subOption => {
            document.getElementById(subOption.id).checked = newCheckedState;
          });
        } else {
          document.getElementById(option.id).checked = newCheckedState;
        }
      });
      updateCheckStates();
    }
  
    function handleKeyDown(event) {
      if (event.key === 'Enter' || event.key === ' ') {
        toggleAll();
        event.preventDefault();
      }
    }
  
    function handleCheckboxChange() {
      updateCheckStates();
    }
  
    // Initialize the state
    $: updateCheckStates();
  </script>
  
  <fieldset class="checkbox-group mb-2">
    <legend 
      class="font-semibold select-toggle" 
      on:click={toggleAll} 
      on:keydown={handleKeyDown} 
      aria-label={`Toggle all ${label}`}>
      <div class="custom-checkbox select-all-checkbox">
        <input type="checkbox" readOnly checked={allChecked} indeterminate={!allChecked && !noneChecked} />
      </div>
      {label}
    </legend>
    <ul class="list-none" style="margin-left: 24px;"> <!-- Add margin-left for indentation -->
      {#each options as option}
        <li class="mb-1">
          {#if option.type === 'checkbox-group'}
            <CheckboxGroup label={option.label} options={option.options} />
          {:else if option.type === 'checkbox'}
            <InputField id={option.id} name={option.name} label={option.label} inputs={option.inputs || []} on:change={handleCheckboxChange} />
          {/if}
        </li>
      {/each}
    </ul>
  </fieldset>
  
  <style>
    .checkbox-group ul.checkboxes {
      list-style: none;
      margin: 1px;
      padding: 0;
    }
  
    .checkbox-group ul.checkboxes li {
      margin: 0;
      padding: 0;
    }
  
    .checkbox-group label {
      margin: 4px;
      margin-left: 24px;
      padding: 4px;
    }
  
    .checkbox-group [role="checkbox"] {
      display: inline-block;
      padding: 4px;
      cursor: pointer;
    }
  
    .checkbox-group [role="checkbox"]::before {
      position: relative;
      top: 1px;
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='16' width='16' style='forced-color-adjust: auto;'%3E%3Crect x='2' y='2' height='13' width='13' rx='2' stroke='currentcolor' stroke-width='1' fill-opacity='0' /%3E%3C/svg%3E");
    }
  
    .checkbox-group [role="checkbox"][aria-checked="true"]::before {
      position: relative;
      top: 1px;
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='16' width='16' style='forced-color-adjust: auto;'%3E%3Crect x='2' y='2' height='13' width='13' rx='2' stroke='currentcolor' stroke-width='1' fill-opacity='0' /%3E%3Cpolyline points='4,8 7,12 12,5' fill='none' stroke='currentcolor' stroke-width='2' /%3E%3C/svg%3E");
    }
  
    .checkbox-group [role="checkbox"][aria-checked="mixed"]::before {
      position: relative;
      top: 1px;
      content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='16' width='16' style='forced-color-adjust: auto;'%3E%3Crect x='2' y='2' height='13' width='13' rx='2' stroke='currentcolor' stroke-width='1' fill-opacity='0' /%3E%3Cline x1='5' y1='5' x2='12' y2='12' stroke='currentcolor' stroke-width='2' /%3E%3C/svg%3E");
    }
  
    .checkbox-group input:focus,
    .checkbox-group [role="checkbox"] {
      outline: none;
    }
  
    .checkbox-group [role="checkbox"]:focus,
    .checkbox-group [role="checkbox"]:hover {
      padding: 4px;
      border: 2px solid #000000;
      border-radius: 5px;
      background-color: rgb(255, 255, 255);
    }
  
    .checkbox-group label.focus,
    .checkbox-group label:hover {
      padding: 2px;
      background-color: rgb(255, 221, 221);
      border-radius: 5px;
      cursor: pointer;
    }
  
    .custom-checkbox {
      display: inline-flex;
      align-items: center;
    }
  
    .custom-checkbox input[type='checkbox'] {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      padding: 0;
      display: inline-block;
      vertical-align: middle;
      background-origin: border-box;
      -webkit-user-select: none;
      -moz-user-select: none;
      user-select: none;
      flex-shrink: 0;
      height: 1rem;
      width: 1rem;
      color: #DC2626;
      background-color: #fff;
      border-color: #71717a;
      border-width: 1px;
      border-radius: 0;
    }
  
    .custom-checkbox input[type='checkbox']:focus {
      outline: 2px solid transparent;
      outline-offset: 2px;
      --tw-ring-offset-width: 2px;
      --tw-ring-offset-color: #fff;
      --tw-ring-color: #DC2626;
      --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
      --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
      box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
    }
  
    .custom-checkbox input[type='checkbox']:checked {
      border-color: transparent;
      background-color: currentColor;
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
    }
  
    .custom-checkbox input[type='checkbox']:indeterminate {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
      border-color: transparent;
      background-color: currentColor;
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;
    }
  
    .custom-checkbox.select-all-checkbox input[type='checkbox'] {
      background-color: #d3d3d3; /* Grey color for select all checkbox */
    }
  </style>
  