<script>

</script>

<!-- Navbar -->
<div class="w-full">
  <nav class="flex flex-wrap items-center justify-between w-full px-6 py-1 mb-1 text-white bg-red-800">
    DZHK-Explorer - Daten für Ihre Forschung
  </nav>
</div>
<!-- End Navbar -->


