<script>
  import { onMount, onDestroy, createEventDispatcher } from "svelte";
  import Chart from "chart.js";

  export let Data;
  export let labels;
  export let popupTitle;


  let chart;
  const dispatch = createEventDispatcher();

  function close() {
    dispatch('close');
  }

  onMount(() => {
    let config = {
      type: "bar",
      data: {
        labels: labels,
        datasets: [
          {
            label: "Daten",
            backgroundColor: "#b33528",
            borderColor: "#b33528",
            data: Data,
            fill: false,
            barThickness: 25,
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        responsive: true,
        title: {
          display: true,
            text: popupTitle,
            fontColor: "#4a5568",
        },
        tooltips: {
          mode: "index",
          intersect: false,
        },
        hover: {
          mode: "nearest",
          intersect: true,
        },
        legend: {
          labels: {
            fontColor: "rgba(0,0,0,.4)",
          },
          align: "end",
          position: "bottom",
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
              },
              gridLines: {
                borderDash: [2],
                borderDashOffset: [2],
                color: "rgba(33, 37, 41, 0.3)",
                zeroLineColor: "rgba(33, 37, 41, 0.3)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: false,
                labelString: "Value",
              },
              gridLines: {
                borderDash: [2],
                drawBorder: false,
                borderDashOffset: [2],
                color: "rgba(33, 37, 41, 0.2)",
                zeroLineColor: "rgba(33, 37, 41, 0.15)",
                zeroLineBorderDash: [2],
                zeroLineBorderDashOffset: [2],
              },
            },
          ],
        },
      },
    };
    let ctx = document.getElementById("popup-chart").getContext("2d");
    chart = new Chart(ctx, config);
  });

  function handleKeydown(event) {
    if (event.key === 'Escape') {
      close();
    }
  }

  onDestroy(() => {
    if (chart) {
      chart.destroy();
    }
  });
  
</script>

<style>
  .popup {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 90%;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    overflow: hidden;
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }

  .chart-container {
    width: 100%;
    height: 100%;
    padding: 20px;
    box-sizing: border-box;
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

</style>

<div class="overlay" on:click={close} on:keydown={handleKeydown}></div>
<div class="popup">
  <button class="close-btn" on:click={close}>&times;</button>
  <div class="chart-container">
    <canvas id="popup-chart"></canvas>
  </div>
</div>
