<script>
    import { onMount, onDestroy, createEventDispatcher } from 'svelte';
    import Chart from 'chart.js';
    import { text } from 'svelte/internal';
  
    export let data;
    export let popupTitle;
  
    let chart;
    const dispatch = createEventDispatcher();
  
    onMount(() => {
      const ctx = document.getElementById('detailed-pie-chart').getContext('2d');
      chart = new Chart(ctx, {
        type: 'pie',
        data: {
          labels: ["Männlich", "Weiblich", "Unbekannt"],
          datasets: [{
            data: data,
            backgroundColor: [
              "rgba(179, 53, 40, 0.4)",
              "rgba(237, 100, 166, 0.4)",
              "rgba(236, 201, 75, 0.4)"
            ],
            hoverBackgroundColor: [
              "rgba(179, 53, 40, 1)",
              "rgba(237, 100, 166, 1)",
              "rgba(236, 201, 75, 1)"
            ]
          }]
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          title: {
            display: true,
            text: popupTitle,
            fontColor: "#4a5568",
          },
          legend: {
            position: 'bottom',
            labels: {
              fontColor: 'rgba(0, 0, 0, 0.7)'
            }
          }
        }
      });
    });
  
    function close() {
      dispatch('close');
    }
  
    onDestroy(() => {
      if (chart) {
        chart.destroy();
      }
    });
  </script>
  
  <style>
    .popup {
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 80%;
      height: 80%;
      background-color: white;
      border-radius: 8px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      z-index: 1000;
      overflow: hidden;
    }
  
    .close-btn {
      position: absolute;
      top: 10px;
      right: 10px;
      background-color: transparent;
      border: none;
      font-size: 20px;
      cursor: pointer;
    }
  
    .chart-container {
      width: 100%;
      height: 100%;
      padding: 20px;
      box-sizing: border-box;
    }
    .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }
  </style>
  
  <div class="overlay" on:click={close} on:keydown></div>
  <div class="popup">
    <button class="close-btn" on:click={close}>&times;</button>
    <div class="chart-container">
      <canvas id="detailed-pie-chart"></canvas>
    </div>
  </div>
  