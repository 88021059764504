<script>
  import CheckboxGroup from './DataSettingsElements/CheckboxGroup.svelte';
  import InputField from './DataSettingsElements/InputField.svelte';
  import { formDataStructure } from './DataSettingsElements/CardSettingForm.js';
  import BigCheckboxGroup from './DataSettingsElements/BigCheckboxGroup.svelte';
  import { navigate } from 'svelte-routing';
  import { isLoading, showSavePopup, currentPage } from '../../store.ts'; // Import the store
  import SavePopup from "../Graphs/SavePopup.svelte"; // Import SavePopup component

  const API_URL = process.env.API_URL;

  async function collectFormData(title = null, comment = null) {
    isLoading.set(true); // Start loading

    const formDataObj = {};
    formDataStructure.forEach(section => {
      section.subsections.forEach(subsection => {
        subsection.fields.forEach(field => {
          if (field.type === 'checkbox-group') {
            field.options.forEach(option => {
              const element = document.getElementById(option.id);
              formDataObj[option.name] = element ? element.checked : null;
            });
          } else {
            const element = document.getElementById(field.id);
            formDataObj[field.name] = element ? element.checked : null;
            if (field.inputs) {
              field.inputs.forEach((input, index) => {
                const inputElement = document.getElementById(`${field.id}-${index}`);
                formDataObj[`${field.name}-${index}`] = inputElement ? inputElement.value : null;
              });
            }
          }
        });
      });
    });

    const requestData = {
      data: formDataObj,
      title: title,
      comment: comment
    };

    try {
      const response = await fetchWithTimeout(`${API_URL}/submit_form`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(requestData),
      }, 5000); // 5 seconds timeout

      const data = await response.json();
      if (data.status === 'success' && data.data.length != 0) {
        console.log(title);
        if (title != null && comment != null){
          showSavePopup.set(false);
          alert('data saved');
        }else
            {
              currentPage.set('results');
              navigate('/admin/results');
            }

      }else {
        alert('There is no data.');
      }
    } catch (error) {
      if (error.name === 'AbortError') {
        alert('Request timed out.');
      } else {
        console.error('Error submitting form:', error);
      }
    } finally {
      isLoading.set(false); // End loading
    }
  }


// Utility function to add timeout to fetch requests
async function fetchWithTimeout(resource, options = {}, timeout = 5000) {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);
  const response = await fetch(resource, {
    ...options,
    signal: controller.signal
  });
  clearTimeout(id);
  return response;
}

  function openSavePopup() {
    showSavePopup.set(true);
  }

  function closeSavePopup() {
    showSavePopup.set(false);
  }

  function handleKeydown(event) {
    if (event.key === 'Enter' || event.key === ' ') {
      if (event.currentTarget === document.getElementById('openSavePopupButton')) {
        openSavePopup();
      } else if (event.currentTarget === document.getElementById('startLoadingButton')) {
        collectFormData();
      } else if (event.currentTarget === document.querySelector('.overlay')) {
        closeSavePopup();
      }
    }
  }
  function clearForm() {
  formDataStructure.forEach(section => {
    section.subsections.forEach(subsection => {
      subsection.fields.forEach(field => {
        // Clear checkbox-group fields
        if (field.type === 'checkbox-group') {
          field.options.forEach(option => {
            const element = document.getElementById(option.id);
            if (element) {
              element.checked = false;
            }
          });
        } else {
          // Clear checkbox fields
          const element = document.getElementById(field.id);
          if (element) {
            element.checked = false;
          }
          // Clear input fields
          if (field.inputs) {
            field.inputs.forEach((input, index) => {
              const inputElement = document.getElementById(`${field.id}-${index}`);
              if (inputElement) {
                inputElement.value = '';  // Reset the value
              }
            });
          }
        }
      });
    });
  });
}

function clearSection(sectionId) {
    const section = document.getElementById(sectionId);
    if (section) {
      const inputs = section.querySelectorAll('input');
      inputs.forEach(input => {
        if (input.type === 'checkbox' || input.type === 'radio') {
          input.checked = false;
        } else {
          input.value = '';
        }
      });
    }
  }

  function fillSection(sectionId) {
    const section = document.getElementById(sectionId);
    if (section) {
      const inputs = section.querySelectorAll('input');
      inputs.forEach(input => {
        switch (input.type) {
          case 'checkbox':
            input.checked = true;
            break;
          case 'number':
            input.value = 100;
            break;
          case 'date':
            input.value = '1900-01-01';
            break;
          default:
            input.value = '';  // Set other input types to empty if needed
        }
      });
    }
  }

  async function saveTemplate(event) {
    const { title, comment } = event.detail;
    await collectFormData(title, comment);
    closeSavePopup();
  }


</script>

<h1 class="text-red-600 text-6xl font-bold flex items-center justify-center h-full" style="font-size: 2rem;">
  Browse our data
</h1>
<div class="flex justify-end p-4 space-x-4">
  <button class="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-700 active:bg-red-800" id="clearFormButton" on:click="{clearForm}" type="button">
    Abfrage zurücksetzen
  </button>
  <button class="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-700 active:bg-red-800" id="openSavePopupButton" on:click="{openSavePopup}" on:keydown="{handleKeydown}" type="button">
    Abfrage speichern
  </button>
  <button class="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-700 active:bg-red-800" disabled="{$isLoading}" id="startLoadingButton" on:click="{collectFormData}" on:keydown="{handleKeydown}" type="button">
    {#if $isLoading}
      <div class="spinner"></div>
    {:else}
      Abfrage starten
    {/if}
  </button>
</div>

<form on:submit|preventDefault={collectFormData}>
  {#each formDataStructure as section, sectionIndex}
    <details class="section" id={`section-${sectionIndex}`}>
      <summary class="section-title">{section.section}</summary>
      {#each section.subsections as subsection}
        <div class="subsection">
          <h3 class="subsection-title">{subsection.title}</h3>
          <table class="subsection-table">
            <thead>
              <tr>
                <th colspan="3"></th>
              </tr>
            </thead>
            <tbody>
              {#each subsection.fields as field, i}
                {#if i % 3 === 0}
                  <tr>
                {/if}
                <td class="field-cell">
                  {#if field.type === 'checkbox-group'}
                    <CheckboxGroup label={field.label} options={field.options} />
                  {:else if field.type === 'checkbox'}
                    <InputField id={field.id} name={field.name} label={field.label} inputs={field.inputs || []} />
                  {:else if field.type === 'big-checkbox-group'}
                    <BigCheckboxGroup label={field.label} options={field.options} />
                  {/if}
                </td>
                {#if i % 3 === 2 || i === subsection.fields.length - 1}
                  {#if (i + 1) % 3 !== 0}
                    <td class="field-cell"></td>
                  {/if}
                {/if}
              {/each}
            </tbody>
          </table>
        </div>
      {/each}
      <div class="flex justify-end p-4 space-x-4">
        <button class="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-700 active:bg-red-800" on:click={() => clearSection(`section-${sectionIndex}`)} type="button">
          Zurücksetzen
        </button>
        <button class="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-700 active:bg-red-800" on:click={() => fillSection(`section-${sectionIndex}`)} type="button">
          Alle auswählen
        </button>
      </div>
    </details>
  {/each}
</form>


{#if $showSavePopup}
  <div class="overlay" on:click={closeSavePopup} on:keydown={handleKeydown}>
    <SavePopup on:close="{closeSavePopup}" on:save={saveTemplate} />
  </div>
{/if}

<style>
  .section {
    background-color: rgba(228, 228, 231, 1);
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-bottom: 1rem;
    padding: 1rem;
  }
  .section-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    cursor: pointer;
  }
  .subsection {
    margin-bottom: 1rem;
  }
  .subsection-title {
    font-size: 1.25rem;
    font-weight: bold;
    color: #373737;
    margin-bottom: 0.5rem;
  }
  .subsection-table {
    width: 100%;
    margin-bottom: 1rem;
    margin-left: 0rem;
    border-collapse: collapse;
  }
  .field-cell {
    padding: 0.5rem;
    border: 1px solid #ddd;
    background-color: #e4ebf2;
  }
  .subsection-table tr:nth-child(odd) .field-cell {
    background-color: #e4ebf2;
  }
  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid white;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 9998;
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>