import { writable } from 'svelte/store';

// Helper function to create a writable store that persists to localStorage
function persist(key, initialValue) {
    const storedValue = localStorage.getItem(key);
    const data = storedValue ? JSON.parse(storedValue) : initialValue;
    
    const store = writable(data);

    store.subscribe(value => {
        localStorage.setItem(key, JSON.stringify(value));
    });

    return store;
}

export const isLoading = writable(false);
export const showSavePopup = writable(false);
export const currentPage = persist('currentPage', 'search');