<div
  class="relative flex flex-col w-full min-w-0 mb-6 break-words border-0 rounded-lg shadow-lg bg-blueGray-100"
>
  <div class="px-6 py-3 mb-0 bg-white border-4 rounded-t">
    <div class="flex justify-between pb-2 text-center">
      <h5 class="text-red-600 text-1xl font-semi">Rechtliche Hinweise</h5>
    </div>
    <div class="flex-auto px-0 py-0 pt-0 lg:px-2">
      <form>
        <div class="p-4 m-2 bg-gray-200 rounded-lg shadow-md">
          <h3><strong><u>Website-Autor</u> </strong></h3>
          <p class="pb-2">
            Georg-August-Universität Göttingen <br />
            Stiftung Öffentliches Recht <br />
            Universitätsmedizin Göttingen <br>
            Robert-Koch-Str. 40 <br>
            37075 Göttingen <br>
          </p>

          <p class="pb-2">
            <strong>Postadresse: </strong> 37099 Göttingen <br />
          </p>

          <p class="pb-2">
            <strong>vertreten durch den Vorstand </strong><br />
            Telefon: +49 (0)551 390 <br />
            E-Mail: poststelle-med.uni-goettingen.de <br />
            (unten: 'Universität Göttingen') 
          </p>

          <p class="pb-2">
            <strong
              >
              Aufsicht</strong
            ><br />
            Stiftungsausschuss des Universitätsklinikums Göttingen <br />
            Umsatzsteuer-Identifikationsnummer <br />
            DE 286005408 <br>
            Verantwortlich für den Inhalt des Dachportals des Universitätsklinikums im Sinne von 5, Abs. 2 TMG <br>
          </p>

          <p class="pt-1 pb-2" style="text-align: justify;">
            Vorstand des Universitätsklinikum Göttingen<br>
            Vorstand, Forschung und Lehre: Prof. Dr. Wolfgang Brück <br>
            Vorstand, Gesundheitsvorsorge: Prof. Dr. Lorenz Trümper <br>
            VorstandGeschäftsführung und Verwaltung: Jens Finke <br>
            </p>
            
          <p class="pt-1 pb-2" style="text-align: justify;">
            <strong>Inhalt und Betrieb</strong> 
          </p>
          <p class="pt-1 pb-2" style="text-align: justify;">
            <strong>Geltungsbereich der Rechtsmitteilung</strong>
            Dieser Rechtsbenachrichtigung gilt für die zentralen Informationen des 
            Vorstandes des Universitätsklinikums Göttingen, der in diesem 
            Rechtsbescheid genannt wird und über die URL www.med.uni-goettingen.de, 
            www.umg.eu und www.universitaets-goettingen.de sowie auf alle Homepages 
            und Websites der Fakultäten zugegriffen werden kann.Links oder Verweise 
            auf diesen Rechtshinweisen. Für alle anderen Seiten dieses www-Servers 
            liegt die Verantwortung für Inhalte bei den jeweiligen Entitäten oder 
            Personen, die die Seiten erstellt haben. 
          </p>
          <p class="pt-1 pb-2" style="text-align: justify;">
            <strong>Haftungsausschluss zu Online-Inhalten</strong>
            Der Inhalt dieser Website wurde mit größtmöglicher Sorgfalt erstellt. Das
             Universitätsklinikum Göttingen übernimmt jedoch keine Gewähr für die 
             Aktualität, Genauigkeit, Vollständigkeit und Qualität der bereitgestellten
              Informationen. Die Universität Göttingen haftet nicht für Schäden materieller
              oder immaterieller Art, die direkt oder indirekt durch die Nutzung oder 
              Nichtnutzung des Inhalts oder durch die Nutzung oder Nichtnutzung fehlerhafter 
              und unvollständiger Informationen entstehen. Informationen werden auf eigene 
              Gefahr des Nutzers verwendet. Obwohl die Inhalte sorgfältig geprüft werden,
              wird keine Haftung für die Inhalte externer Links übernommen. Die Betreiber 
              der verlinkten Seiten tragen allein die Verantwortung für deren Inhalt. Die 
              laufenden Kontrollen der Inhalte der verlinkten Seiten können nicht ohne 
              konkrete Hinweise auf Rechtsverletzung durchgeführt werden. Sollte ein 
              solcher Verstoß festgestellt werden, werden Verbindungen dieser Art sofort 
              beseitigt. Das Universitätsklinikum Göttingen behält sich das Recht vor, 
              Seitenteile oder die gesamte Onlinepräsenz ohne gesonderte Benachrichtigung 
              zu ändern, zu erweitern oder zu löschen. Urheber- und Markenrecht
          </p>
          <p class="pt-1 pb-2" style="text-align: justify;">
            Das Layout der Homepage, die verwendeten Grafiken und deren sonstige Inhalte 
            der Online-Präsenz des Universitätsklinikums Göttingen unterliegen dem deutschen
            Urheber- und Markenrecht. Das Universitätsklinikum Göttingen ist stets bemüht,
            das Urheberrecht anderer Parteien zu beachten und die selbst produzierten oder
            nicht lizenzrechtlichen Beschränkungen zu nutzen. Jede Nutzung, die nach 
            deutschem Urheber- und Markenrecht nicht gestattet ist, unterliegt der 
            vorherigen schriftlichen Zustimmung des Universitätsklinikums Göttingen oder 
            des jeweiligen Rechteinhabers. Dies gilt insbesondere für die Vervielfältigung,
            Bearbeitung, Übersetzung, Speicherung, Bearbeitung oder Wiedergabe von Inhalten
            in Datenbanken oder anderen elektronischen Medien und Systemen. Hier werden Inhalt
            und Rechte Dritter als solche gekennzeichnet. Die unbefugte Vervielfältigung oder
             Weiterleitung einzelner Inhalte oder ganzer Seiten ist nicht zulässig und ist
             bestraft. Kopien und Downloads von Internetseiten sind nur für private, 
             wissenschaftliche und nicht-kommerzielle Nutzung erlaubt. Diese Website darf 
             nur nach vorheriger schriftlicher Genehmigung in externen Frames angezeigt werden. 
          </p>

          <p class="pt-1 pb-2" style="text-align: justify;">
            <strong>Verwendung des Namens und der Marke "UMG"</strong>
            Der Name "UMG" ist als europäische Marke registriert. Die Nutzung für kommerzielle
            Zwecke (insbesondere für Werbezwecke) ist nur nach vorheriger schriftlicher 
            Genehmigung durch das Universitätsklinikum Göttingen als Markeninhaber zulässig.
          </p>
        </div>
      </form>
    </div>
  </div>
</div>
