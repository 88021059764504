<script>
  export let data = [];
</script>

{#if data.length > 0}
  <table class="min-w-full bg-white fixed-table">
    <thead>
    <tr>
      {#each Object.keys(data[0] || {}) as key}
        <th class="border-b border-gray-200">{key}</th>
      {/each}
    </tr>
    </thead>
    <tbody>
    {#each data as row}
      <tr>
        {#each Object.values(row) as value}
          <td class="border-b border-gray-200">{value}</td>
        {/each}
      </tr>
    {/each}
    </tbody>
  </table>
{:else}
  <p>No data available to display.</p>
{/if}

<style>
  th, td {
    background-color: #f5f5f5;
    text-align: left;
    /* Remove padding */
  }
  .fixed-table {
    width: 100%;  /* Ensure it fits within the grid item */
    height: 100%;  /* Ensure it fits within the grid item */
    border-collapse: collapse; /* Add this to ensure borders collapse */
    margin-left: 0; /* Remove margin-left */
  }
</style>
