<script>
  import { link } from "svelte-routing";

</script>

<div
  class="relative flex flex-col w-full min-w-0 mb-6 break-words border-0 rounded-lg shadow-lg bg-blueGray-100"
>
  <div class="px-6 py-3 mb-0 bg-white border-4 rounded-t">
    <div class="flex justify-between pb-2 text-center">
      <h6 class="text-red-600 text-1xl font-semi">Hilfe/Support</h6>
    </div>
    <div class="flex-auto px-0 py-0 pt-0 lg:px-2">
      <form>
        <div class="p-4 m-2 bg-gray-200 rounded-lg shadow-md">
          <h3><strong><u>Kontakt</u></strong></h3>
           <div class="pt-1 pb-2" style="text-align: justify;">
           <strong>Kontakt:</strong><br>Transfer Office
           Sabine Hanß, Masha Lee
           use.access@dzhk.de
        </div>
          <h3><strong><u>Aufbau des Feasibility Explorers</u> </strong></h3>
          <div class="pt-1 pb-2" style="text-align: justify;">
            Der Feasibility Explorer ist das Werkzeug zur Auswahl von
            Kollektiven, um einen Daten-/Probennutzungsantrag im DZHK zu
            stellen. Antragsstellende können sich mithilfe des Feasibility
            Explorers einen Überblick über die im DZHK verfügbaren Daten und
            Biomaterialien verschaffen. Anhand verschiedener Filtereinstellungen
            kann die Grundgesamtheit eingeschränkt und ein Kollektiv gebildet
            werden, das geeignet ist, die dem Nutzungsantrag zugrundeliegende
            Fragestellung zu untersuchen. Wenn die Größe des ausgewählten
            Kollektivs für die Beantwortung der Fragestellung hinreichend ist,
            dann empfiehlt sich die Stellung eines Antrags auf
            Daten-/Probennutzung. <br />
            Anhand von Abbildungen werden im Folgenden Funktionen und die Nutzung
            des Feasibility Explorers näher erläutert.
          </div>
          <h3><strong><u>Inhaltsverzeichnis</u></strong></h3>
          <h3><a href="#Startseite"><u>1. Startseite</u></a></h3>
          <h3><a href="#Suche"><u>2. Suche</u></a></h3>
          <h3><a href="#Daten filtern">&nbsp; &nbsp; 2.1 Daten filtern</a></h3>
          <h3><a href="#Abfrage_starten">&nbsp; &nbsp; 2.2 Abfrage starten/speichern/zurücksetzen</a></h3>
          <h3><a href="#Datenabruf"><u>3. Datenabruf</u></a></h3>
          <h3><a href="#Antragstellung"><u>4. Antragstellung</u></a></h3>
        </div>
      </form>
      <form>
        <div class="p-4 m-2 bg-gray-200 rounded-lg shadow-md">
          <h3 id="Startseite"><strong>1. Startseite</strong></h3>
          <div class="pt-4">
            <img
              src="../assets/img/Dashboard.png"
              style="width: 70%; height: auto"
              alt="Dashboard"
              loading="lazy"
            />
            <div class="pt-2 pb-4" style="font-size: small" align="left">
              Abbildung 1: Gesamtdarstellung des Feasibility Explorers
            </div>
          </div>
          <div class="pt-1" style="text-align: justify;">
            <strong>Abbildung 1</strong> zeigt die Gesamtansicht der ersten Seite
            des Feasibility Explorers. Auf der linken Seite findet sich ein Navigationsbereich,
            der durch das Tool leiten soll. Falls bestimmte Fragestellungen nicht mit dem Tool
            beantwortet werden können, findet sich zusätzlich in diesem Bereich eine Kontaktmöglichkeit,
            um sich mit den Verantwortlichkeiten des DZHK-Explorers in Verbindung zu setzen. 
            Inhaltlicher Kontext der ersten Seite ist eine grafische Darstellung zur Verfügbarkeit
            von Bioproben, einer Geschlechts- und Altersverteilung sowie die Verfügbarkeit von Bild- und
            Biosignaldaten. Balkendiagramme, wie zur Verfügbarkeit von Bioproben
            und Bild-/Biosignaldaten, können durch den Button "Zoom" vergrößert werden, um
            Detailansichten herzustellen. Weitere Detailansichten können per Mouseover-Effekt
            über das Kreisdiagramm zur Geschlechtsverteilung und dem Balkendiagramm
            zur Altersverteilung hervorgehoben werden. Je Diagramm führt der Button
            "Durchsuchen" zur Auswahlseite von Filtern, welche im Folgenden
            beschrieben wird. In der Fußzeile jeder Seite können darüber hinaus,
            per Verlinkungen, weitere Informationen zum DZHK, zum Datenschutz und
            zu rechtlichen Hinweisen gewonnen werden. Auch das Copyright der gesamten Webseite
            wird hier dargestellt.
          </div>
        </div>
      </form>
      <form>
        <div class="p-4 m-2 bg-gray-200 rounded-lg shadow-md">
          <h3 id="Suche"><strong>2. Suche</strong></h3>
          <div class="pt-2" id="Daten filtern">2.1 Daten filtern
            <img
              class="pt-2"
              src="../assets/img/Search_1.png"
              style="width: 70%; height: auto"
              alt="Search_1"
              loading="lazy"
            />
            <div class="pt-2 pb-4" style="font-size: small" align="left">
              Abbildung 2: Kollektiverstellung
            </div>
          </div>
          <div class="pt-1 pb-2" style="text-align: justify;">
            <strong>Abbildung 2</strong>, und damit die Seite der Suche, bildet die verschiedenen
            Funktionalitäten ab, die im Feasibility Explorer zur
            Kollektiverstellung verfügbar sind. Funktionen zur Auswahl von Daten
            der Grundgesamtheit, zum Entfernen gesetzter Filter und zum
            Speichern vorgenommener Filtereinstellungen werden anhand dieser
            Abbildung in den folgenden Abschnitten beschrieben. <br />
          </div>
          <div class="pt-4">
            <img
              src="../assets/img/Search_2.png"
              style="width: 70%; height: auto"
              alt="Search_2"
              loading="lazy"
            />
            <div class="pt-2 pb-4" style="font-size: small" align="left">
              Abbildung 3: Auswahlfelder
            </div>
            <div class="pt-1 pb-2" style="text-align: justify;">
              Die Grundgesamtheit kann durch verschiedene Filtermöglichkeiten
              eingeschränkt und somit ein den eigenen Anforderungen
              entsprechendes Kollektiv zusammengestellt werden. Die Hinzunahme
              eines Filters bewirkt eine weitere Einschränkung des bisherigen
              Teildatensatzes. Durch Auswahlfelder <strong>(s. Abbildung 3)</strong> können die
              zur Verfügung stehenden Merkmale ausgewählt werden. Die Hinzunahme
              eines Filters bewirkt eine weitere Einschränkung des bisherigen
              Teildatensatzes. Es besteht dabei die Möglichkeit bei Filtern mit
              Subfilterung entweder den insgesamten Filter auszuwählen, mit all
              seinen möglichen Ausprägungen oder lediglich vereinzelte
              Ausprägungen. Des Weiteren bestehen Felder, in denen Werte
              eingetragen werden können, um bestimmte Wertebereiche zu bekommen
              oder es können verschiedene Einheiten je Filter ausgewählt werden.<br
              />
            </div>
            <div class="pt-4" id="Abfrage_starten">2.2 Abfrage starten/speichern/zurücksetzen
              <img
                class="pt-2"
                src="../assets/img/Search_3.png"
                style="width: 70%; height: auto"
                alt="Search_3"
                loading="lazy"
              />
              <div class="pt-2 pb-4" style="font-size: small" align="left">
                Abbildung 4: Gesamte Abfrage starten/speichern/zurücksetzen
              </div>
              <div class="pt-1 pb-2" style="text-align: justify;">
                Wurden die Filter ausgewählt, kann die Abfrage einerseits
                gestartet, zurückgesetzt und gespeichert werden. Dies geschieht
                durch drei Buttons, die am oberen Rand des Formulars angeklickt
                werden können. Nach starten einer Abfrage werden Datensätze
                gesucht, welche mit der Abfrage übereinstimmen und eine
                Ergebnisseite wird erstellt. Bei "Abfrage speichern" wird ein
                Popup-Fenster (s. Abbildung 7) geöffnet. Eine Abfrage kann
                auch komplett zurückgesetzt werden, wenn das Ergebnis nicht wie
                gewünscht ausgeführt wurde oder Filter falsch gesetzt wurden.<br />
              </div>
              <div class="pt-4">
                <img
                  src="../assets/img/Search_4.png"
                  style="width: 70%; height: auto"
                  alt="Search_4"
                  loading="lazy"
                />
                <div class="pt-2 pb-4" style="font-size: small" align="left">
                  Abbildung 5: Filter je Bereich anwenden/zurücksetzen
                </div>
                <div class="pt-1 pb-2" style="text-align: justify;">
                  Filter können darüber hinaus je Bereich, d.h. Filter bei "Klinischen
                  Daten", "Bioproben" oder "Bild- und Biosignalen" zurückgesetzt
                  oder komplett je Bereich ausgewählt werden. Dafür können die 
                  angegebenen Buttons ausgewählt werden.<br />
                </div>
                <div class="pt-4">
                  <img
                    src="../assets/img/Search_5.png"
                    style="width: 70%; height: auto"
                    alt="Search_5"
                    loading="lazy"
                  />
                  <div class="pt-2 pb-4" style="font-size: small" align="left">
                    Abbildung 6: Popup-Fenster nach Start ohne Treffer
                  </div>
                  <div class="pt-1 pb-2" style="text-align: justify;">
                    Falls nach Start einer Abfrage keine Daten mit den ausgewählten Filtern
                    gefunden werden können, d.h. 0 Treffer gefunden wurden, wird dies
                    anhand eines Popup-Fensters kenntlich gemacht.<br />
                  </div>
                  <div class="pt-4">
                    <img
                      src="../assets/img/Search_6.png"
                      style="width: 70%; height: auto"
                      alt="Search_6"
                      loading="lazy"
                    />
                    <div
                      class="pt-2 pb-4"
                      style="font-size: small"
                      align="left"
                    >
                      Abbildung 7: Datenabfrage speichern
                    </div>
                    <div class="pt-1 pb-2" style="text-align: justify;">
                      Nach Auswahl des Buttons "Abfrage speichern" öffnet sich
                      ein Popup-Fenster, indem selbstständig ein Titel für die
                      Abfrage und ein Kommentar hinterlegt werden kann. Diese
                      Abfrage wird anschließend unter "Datenabruf" in einer
                      Tabelle hinterlegt. Zu einem späteren Zeitpunkt können so
                      alte bereits gespeicherte Abfragen erneut ausgeführt
                      werden oder diese werden genutzt um weitere Felder
                      hinzuzufügen. Dies ermöglicht eine Historienkontrolle
                      aller bisherig durchgeführten Abfragen und eine
                      Erleichterung des Arbeitsumfanges.
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <form>
        <div class="p-4 m-2 bg-gray-200 rounded-lg shadow-md">
          <h3 id="Datenabruf"><strong>Datenabruf</strong></h3>
          <div class="pt-4">
            <img
              src="../assets/img/Search_7.png"
              style="width: 70%; height: auto"
              alt="Search_7"
              loading="lazy"
            />

            <div class="pt-2 pb-4" style="font-size: small" align="left">
              Abbildung 7: Datenabruf
            </div>
            <div class="pt-1 pb-2" style="text-align: justify;">
              Nach Speicherung einer Abfrage, kann diese unter dem Reiter
              "Datenabruf" eingesehen werden. Dabei wird jede einzelne Abfrage
              mit Datum, Titel der Abfrage, Anzahl Studienteilnehmende und Anzahl Bioproben
              dargestellt.
              <br />
            </div>
          </div>
        </div>
      </form>

      <form>
        <div class="p-4 m-2 bg-gray-200 rounded-lg shadow-md">
          <h3 id="Antragstellung"><strong>Antragstellung</strong></h3>
          <p class="pt-1 pb-2" style="text-align: justify;">
            Die DZHK Heart Bank beinhaltet umfangreiche kardiovaskuläre
            Ressourcen. Diese umfassen qualitativ hochwertige klinische Daten,
            Bilddaten, OMICs Daten sowie Flüssig- und Gewebeproben inkl.
            dazugehöriger Daten deren Verarbeitung und Lagerung zuverlässig und
            unter standardisierten Bedingungen erfolgt. Wie Sie sich eine
            passende Auswahl an Daten und Bioproben aus der DZHK Heart Bank für
            Ihr Forschungsprojekt zusammenstellen und wie diese dann zu
            beantragen sind, erklären wir Ihnen
            <strong
              ><a
                use:link
                href="https://dzhk.de/dzhk-heart-bank/antragstellung/"
              >
                hier.
              </a></strong
            >
          </p>
        </div>
      </form>
    </div>
  </div>
</div>
