<script>
	import { onMount } from 'svelte';
	import { isLoading } from '../../store.ts';
  
	let savedQueries = [];
  
	const API_URL = process.env.API_URL;
  
	async function fetchSavedQueries() {
	  isLoading.set(true); // Start loading
	  try {
		const response = await fetch(`${API_URL}/get_saved_queries`, {
		  method: 'GET',
		  headers: {
			'Content-Type': 'application/json',
		  },
		  credentials: 'include',
		});
		const data = await response.json();
		if (data.status === 'success') {
		  savedQueries = data.data;
		} else {
		  alert('Error fetching saved queries.');
		}
	  } catch (error) {
		console.error('Error fetching saved queries:', error);
	  } finally {
		isLoading.set(false); // End loading
	  }
	}
  
	onMount(() => {
	  fetchSavedQueries();
	});
  </script>
  
  <style>
	table {
	  border-collapse: separate;
	  border-spacing: 0.2em;
	  margin-left: 2em;
	  box-shadow: 0 0 0.1em #666;
	  position: relative;
	  z-index: 1;
	}
  
	th, td {
	  padding: 0.2em 0.5em;
	  border-radius: 0.1em;
	}
  
	thead th {
	  background-color: #ffebe6;
	  color: #c32e04;
	}
  
	td {
	  font-style: normal;
	  text-align: left;
	  box-shadow: inset 1px 3px 5px -3px rgba(0, 0, 0, 0.5);
	}
  
	tbody tr:nth-child(even) {
	  background-color: #e4ebf2;
	  color: #000;
	}
  
	td:empty {
	  box-shadow: none;
	  background-color: transparent;
	}
  
	th[scope=row] {
	  color: #c32e04;
	  text-align: left;
	  background-color: #fff;
	}
  
	caption {
	  color: white;
	  background: rgba(153, 27, 27);
	  font-size: 1.5em;
	  text-align: left;
	  box-shadow: 0.1em 0.1em 0.1em 0 white;
	  padding: 0.1em 0.1em 0.1em 1em;
	  margin-bottom: 0.15em;
	  width: 40%;
	  margin-left: -0.6em;
	  position: relative;
	}
  
	caption::before {
	  content: "";
	  position: absolute;
	  display: block;
	  height: 0;
	  width: 0;
	  top: 1em;
	  left: -0.6em;
	  border-style: solid;
	  border-width: 0.6em;
	  border-color: transparent hsl(13, 96%, 35%) transparent transparent;
	  z-index: -1;
	}
  
	tbody tr:hover, tfoot tr:hover {
	  background-color: #fff0f0;
	}
  
	tbody td:hover, tfoot td:hover {
	  background-color: #fca2a2;
	}
  
	table {
	  text-indent: 0;
	  border-color: inherit;
	}
  </style>
  
  <form>
	<div
	  class="relative flex flex-col w-full min-w-0 mb-6 break-words border-0 rounded-lg shadow-lg bg-blueGray-100"
	>
	<div class="px-6 py-3 mb-0 bg-white border-4 rounded-t">
	  <div class="flex justify-between pb-2 text-center">
  
	  </div>
	  <table class="w-10/12">
		<caption>Gespeicherte Abfragen</caption>
		<thead>
		  <tr>
			<td rowspan="2"></td>
			<th scope="col" rowspan="2">Datum</th>
			<th scope="colgroup" colspan="2">Gesamtzahlen</th>
			<th scope="col" rowspan="2">Filtername</th>
		  </tr>
		  <tr>
			<th scope="col">Patientenanzahl</th>
			<th scope="col">Anzahl Bioproben</th>
		  </tr>
		</thead>
		<tbody>
		  {#each savedQueries as query}
			<tr>
			  <th scope="row">{query.id}</th>
			  <td>{query.date}</td>
			  <td>{query.patient_count}</td>
			  <td>{query.bioproben_count || 'N/A'}</td>
			  <td>{query.title}</td> <!-- Assuming there is a status field or defaulting to 'N/A' -->
			</tr>
		  {/each}
		</tbody>
		<tfoot>
		  <tr>
			<th scope="row"></th>
			<td></td>
			<td></td>
			<td></td>
			<td></td>
		  </tr>
		</tfoot>
	  </table>
	</div>
	</div>
  </form>
  