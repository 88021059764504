<script>
  // core components
  import CardTable from "components/Cards/CardTable.svelte";
  //export let location;
</script>

<div class="flex flex-wrap mt-4">
  <div class="w-full px-4 mb-12">
    <CardTable />
  </div>
</div>
