<script>
  import CardBarChart_1 from "components/Cards/CardBarChart_1.svelte";
  import CardBarChart_2 from "components/Cards/CardBarChart_2.svelte";
  import CardPieChart from "components/Cards/CardPieChart.svelte";
  import CardBarChart_3 from "components/Cards/CardBarChart_3.svelte";

  import { onMount } from 'svelte';

  const NEW_API_URL = process.env.API_URL; // Ensure this is correctly set

  let overviewData = {};  // Initialize as an empty object
  let error = null;  // To store and display any errors

  async function fetchData() {
      try {
          console.log("url ",NEW_API_URL);
          const response = await fetch(`${NEW_API_URL}/overview`);
          const data = await response.json();
          console.log("Complete API response:", data); // Log the complete response

          if (!response.ok) {
              throw new Error(`Network response was not ok: ${response.status}`);
          }
          if (data) {
              overviewData = data;  // Use the data as received
          } else {
              throw new Error("Received data is not in expected format");
          }
      } catch (err) {
          error = err.message;
          console.error('Error fetching data:', err);
          overviewData = {};  // Reset to an empty object on error
      }
  }

  onMount(fetchData);
</script>

<!-- Reactively display content based on the state of data fetching -->
{#if Object.keys(overviewData).length > 0}
  <div>
      <div class="flex flex-wrap">
          <div class="w-full px-4 xl:w-6/12">
              <CardBarChart_2 {overviewData}/>
          </div>
          <div class="w-full px-4 xl:w-6/12">
              <CardPieChart {overviewData}/>
          </div>
      </div>
      <div class="flex flex-wrap mt-4">
         <div class="w-full px-4 xl:w-6/12">
              <CardBarChart_1 {overviewData}/>
          </div>
          <div class="w-full px-4 xl:w-6/12">
              <CardBarChart_3 {overviewData}/>
          </div>
      </div>
  </div>
{:else if error}
  <p>Error loading data: {error}</p>
{:else}
  <p>Loading...</p>
{/if}
