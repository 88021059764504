<script>
  import { onMount } from "svelte";
  import Chart from "chart.js";
  import PopupChart from "../Graphs/BarChartPopup_1.svelte";
  import InfoBubble from '../Graphs/InfoBubble.svelte';

  export let overviewData;

  let showPopup = false;
  let chart;
  let popupTitle = "Verfügbarkeit von Bioproben";
  let popupData = {};

  $: if (overviewData && Object.keys(overviewData).length > 0) {
    popupData = {
      "Buffy-Coat": overviewData?.BioSpecimens?.EDTABUF ?? 0,
      "Citrat-Plasma": overviewData?.BioSpecimens?.CIT ?? 0,
      "EDTA-Plasma": overviewData?.BioSpecimens?.EDTA ?? 0,
      "Serum": overviewData?.BioSpecimens?.SER ?? 0,
      "Urin": overviewData?.BioSpecimens?.URN ?? 0
    };
  }

  onMount(() => {
    if (Object.keys(overviewData).length > 0) {
      initializeChart();
    }
  });

  function initializeChart() {
    let Data = {
      "Buffy-Coat": overviewData?.BioSpecimens?.EDTABUF ?? 0,
      "Citrat-Plasma": overviewData?.BioSpecimens?.CIT ?? 0,
      "EDTA-Plasma": overviewData?.BioSpecimens?.EDTA ?? 0,
      "Serum": overviewData?.BioSpecimens?.SER ?? 0,
      "Urin": overviewData?.BioSpecimens?.URN ?? 0,
    };

    const ctx = document.getElementById("bar-chart").getContext("2d");
    chart = new Chart(ctx, {
      type: "bar",
      data: {
        labels: Object.keys(Data),
        datasets: [
          {
            label: "Insgesamt",
            backgroundColor: "#b33528",
            borderColor: "#b33528",
            data: Object.values(Data),
            fill: false,
            barThickness: 15
          },
        ]
      },
      options: chartOptions
    });
  }

  function openPopup() {
    showPopup = true;
  }

  function closePopup() {
    showPopup = false;
  }

  function handleKeyPress(event) {
    if (event.key === 'Enter' || event.key === ' ') {
      openPopup();
    }
  }

  const chartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    title: {
      display: true,
      fontColor: "#4a5568"
    },
    tooltips: {
      mode: "index",
      intersect: false
    },
    hover: {
      mode: "nearest",
      intersect: true
    },
    legend: {
      labels: {
        fontColor: "rgba(0,0,0,.4)"
      },
      align: "end",
      position: "bottom"
    },
    scales: {
      xAxes: [{
        display: true,
        scaleLabel: {
          display: true,
          labelString: ""
        },
        gridLines: {
          borderDash: [2],
          borderDashOffset: [2],
          color: "rgba(33, 37, 41, 0.3)",
          zeroLineColor: "rgba(33, 37, 41, 0.3)",
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2]
        }
      }],
      yAxes: [{
        display: true,
        scaleLabel: {
          display: true,
          labelString: "Studienteilnehmende"
        },
        ticks: {
          beginAtZero: true
        },
        gridLines: {
          borderDash: [2],
          drawBorder: false,
          borderDashOffset: [2],
          color: "rgba(33, 37, 41, 0.2)",
          zeroLineColor: "rgba(33, 37, 41, 0.15)",
          zeroLineBorderDash: [2],
          zeroLineBorderDashOffset: [2]
        }
      }]
    }
  };
</script>

<div class="relative flex flex-col w-full min-w-0 break-words bg-white rounded shadow-lg min-w-30">
  <div class="px-4 py-3 mb-0 bg-transparent rounded-t">
    <div class="flex flex-wrap items-center">
      <div class="relative flex-1 flex-grow w-full max-w-full">
        <h6 class="mb-1 text-xs font-semibold uppercase text-blueGray-600">
          Verfügbarkeit von Bioproben
          <span class="block mt-2 px-2 py-1 bg-gray-100 rounded shadow text-sm font-bold text-gray-700 ">
            Insgesamt: {overviewData?.TotalBiosamplePatient || '0'}
          </span>
        </h6>
      </div>
      <div class="relative flex-1 flex-grow w-full max-w-full px-4 text-right">
        <button class="px-6 py-1 mb-1 mr-1 text-xs font-bold text-white uppercase transition-all duration-150 ease-linear bg-red-800 rounded outline-none active:bg-indigo-600 focus:outline-none" on:click={openPopup} on:keydown={handleKeyPress}>
          Zoom
        </button>
        {#if window.location.pathname !== '/admin/results'}
        <a class="px-3 py-1 mb-1 mr-1 text-xs font-bold text-white uppercase transition-all duration-150 ease-linear bg-red-800 rounded outline-none active:bg-indigo-600 focus:outline-none" href="/admin/settings">
          Durchsuchen
        </a>
        {/if}
        <InfoBubble infoText="Stellt die Anzahl der Studienteilnehmende mit Bioproben dar." />
      </div>
    </div>
  </div>
  <div class="flex-auto p-4">
    <div class="relative h-350-px">
      <canvas id="bar-chart"></canvas>
    </div>
  </div>
</div>

{#if showPopup}
  <PopupChart {popupData} {popupTitle} on:close={closePopup}/>
{/if}
