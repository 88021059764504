<script>
    import { onMount, onDestroy } from "svelte";
    import Chart from "chart.js";

    export let overviewData;

    let chart;

    onMount(async () => {
        try {
            const data = overviewData;
            const labels = generateProgressiveList(data["ST_BasisGebdatum"]);
            const values = data["ST_BasisGebdatum"];

            const ctx = document.getElementById("age-distribution-chart").getContext("2d");
            chart = new Chart(ctx, {
                type: "bar",
                data: {
                    labels: labels,
                    datasets: [{
                        label: "Studienteilnehmende",
                        backgroundColor: "#b33528",  // Red color for bars
                        borderColor: "#b33528",  // Red color for border
                        data: values,
                        fill: false,
                        barThickness: 'flex',
                        barPercentage: 1.0,
                        categoryPercentage: 1.0
                    }]
                },
                options: {
                    maintainAspectRatio: false,
                    responsive: true,
                    title: {
                        display: true,
                        text: "Altersverteilung nach Jahrzehnten",
                        fontColor: "#4a5568"
                    },
                    tooltips: {
                        mode: "index",
                        intersect: false
                    },
                    hover: {
                        mode: "nearest",
                        intersect: true
                    },
                    legend: {
                        display: false // Hide the legend for a more histogram-like look
                    },
                    scales: {
                        xAxes: [{
                            display: true,
                            scaleLabel: {
                                display: true,
                                labelString: "Jahrzehnt"
                            },
                            gridLines: {
                                display: false, // Hide grid lines for x-axis
                                borderDash: [2],
                                borderDashOffset: [2],
                                color: "rgba(33, 37, 41, 0.3)",
                                zeroLineColor: "rgba(33, 37, 41, 0.3)",
                                zeroLineBorderDash: [2],
                                zeroLineBorderDashOffset: [2]
                            }
                        }],
                        yAxes: [{
                            display: true,
                            scaleLabel: {
                                display: true,
                                labelString: "Studienteilnehmende"
                            },
                            ticks: {
                                beginAtZero: true,
                                stepSize: 10  // Set the step size for y-axis graduations
                            },
                            gridLines: {
                                borderDash: [2],
                                drawBorder: false,
                                borderDashOffset: [2],
                                color: "rgba(33, 37, 41, 0.2)",
                                zeroLineColor: "rgba(33, 37, 41, 0.15)",
                                zeroLineBorderDash: [2],
                                zeroLineBorderDashOffset: [2]
                            }
                        }]
                    }
                }
            });
        } catch (error) {
            console.error('Failed to fetch data:', error);
        }
    });

    function generateProgressiveList(referenceList) {
    let result = [];
    let currentValue = 0;
        for (let i = 0; i < referenceList.length; i++) {
            result.push(currentValue);
            currentValue += 10;
        }
    
    return result;
}

    onDestroy(() => {
        if (chart) {
            chart.destroy();
        }
    });
</script>

<div class="relative flex flex-col w-full min-w-0 break-words bg-white rounded shadow-lg min-w-30">
    <div class="px-4 py-3 mb-0 bg-transparent rounded-t">
        <div class="flex flex-wrap items-center">
            <div class="relative flex-1 flex-grow w-full max-w-full">
                <h6 class="mb-1 text-xs font-semibold uppercase text-blueGray-600">
                    Altersverteilung
                  <span class="block mt-2 px-2 py-1 bg-gray-100 rounded shadow text-sm font-bold text-gray-700 ">
                    Ø Alter: 123
                  </span>
                </h6>
            </div>
            <div class="relative flex-1 flex-grow w-full max-w-full px-4 text-right">
              <a
                class="px-3 py-1 mb-1 mr-1 text-xs font-bold text-white uppercase transition-all duration-150 ease-linear bg-red-800 rounded outline-none active:bg-indigo-600 focus:outline-none"
                href="/admin/settings"
              >
                Browse our data
              </a>
            </div>
        </div>
    </div>
    <div class="flex-auto p-4">
        <div class="relative h-350-px">
            <canvas id="age-distribution-chart"></canvas>
        </div>
    </div>
</div>
