<script>
  // core components
  export let statSubtitle = "Traffic";
  export let statTitle = "350,897"; // Default value
  export let statIconName = "far fa-chart-bar";
  export let statIconColor = "bg-red-600";
</script>

<div
        class="relative flex flex-col min-w-0 mt-1 mb-6 break-words bg-white rounded shadow-lg xl:mb-0"
>
  <div class="flex-auto p-4">
    <div class="flex flex-wrap">
      <div class="relative flex-1 flex-grow w-full max-w-full pr-4">
        <h5 class="text-xs font-bold uppercase text-blueGray-400">
          {statSubtitle}
        </h5>
        <span class="text-xl font-semibold text-blueGray-700">
          {statTitle}
        </span>
      </div>
      <div class="relative flex-initial w-auto pl-4">
        <div
                class="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-lg rounded-full {statIconColor}"
        >
          <i class="{statIconName}"></i>
        </div>
      </div>
    </div>
  </div>
</div>
