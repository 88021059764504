<script>
    import { onMount, onDestroy } from "svelte";
    import Chart from "chart.js";
    import BarChartPopup_2 from "../Graphs/BarChartPopup_2.svelte"; // Updated import
    import InfoBubble from '../Graphs/InfoBubble.svelte';


    export let overviewData;

    let showPopup = false;
    let chart;

    let popupTitle = "Altersverteilung";

    const data = overviewData;
    const labels = generateProgressiveList(data["AgeGroup"]);
    const values = data["AgeGroup"];

    function generateProgressiveList(referenceList) {
        let result = [];
        let currentValue = 0;
        for (let i = 0; i < referenceList.length; i++) {
            result.push(currentValue);
            currentValue += 10;
        }
        return result;
    }

    function caculateAlter(ageList){
        let currentValue = 0;
        let diviser = 0;
        for (let i = 0; i < ageList.length; i++) {
            currentValue += ageList[i] * (i * 10 + 1);
            diviser += ageList[i] ;
        }
        return Math.round((currentValue/diviser)+5);
    }

    onMount(() => {

        const ctx = document.getElementById("age-distribution-chart").getContext("2d");
        chart = new Chart(ctx, {
            type: "bar",
            data: {
                labels: labels,
                datasets: [{
                    label: "Studienteilnehmende",
                    backgroundColor: "#b33528",
                    borderColor: "#b33528",
                    data: values,
                    fill: false,
                    barThickness: 'flex'
                }]
            },
            options: chartOptions
        });
    });

    onDestroy(() => {
        if (chart) {
            chart.destroy();
        }
    });

    function openPopup() {
        showPopup = true;
    }

    function closePopup() {
        showPopup = false;
    }

    const chartOptions = {
        maintainAspectRatio: false,
        responsive: true,
        title: {
            display: true,
        },
        tooltips: {
            mode: "index",
            intersect: false
        },
        hover: {
            mode: "nearest",
            intersect: true
        },
        legend: {
            display: false
        },
        scales: {
            xAxes: [{
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: "Aktuelles Alter"
                }
            }],
            yAxes: [{
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: "Studienteilnehmende"
                },
                ticks: {
                    beginAtZero: true
                }
            }]
        }
    };
    function handleKeyPress(event) {
    if (event.key === 'Enter' || event.key === ' ') {
      openPopup();
    }
  }
</script>


<div class="relative flex flex-col w-full min-w-0 break-words bg-white rounded shadow-lg min-w-30">
    <div class="px-4 py-3 mb-0 bg-transparent rounded-t">
        <div class="flex flex-wrap items-center">
            <div class="relative flex-1 flex-grow w-full max-w-full">
                <h6 class="mb-1 text-xs font-semibold uppercase text-blueGray-600">
                    Altersverteilung
                   
                        <span class="block mt-2 px-2 py-1 bg-gray-100 rounded shadow text-sm font-bold text-gray-700 ">
                            Ø Alter: {caculateAlter(values)}
                        </span>
                    
                </h6>
            </div>
            <div class="relative flex-1 flex-grow w-full max-w-full px-4 text-right">
                <button class="px-6 py-1 mb-1 mr-1 text-xs font-bold text-white uppercase transition-all duration-150 ease-linear bg-red-800 rounded outline-none active:bg-indigo-600 focus:outline-none" on:click={openPopup} on:keydown={handleKeyPress}>
                    Zoom
                </button>
                {#if window.location.pathname !== '/admin/results'}
                    <a
                    class="px-3 py-1 mb-1 mr-1 text-xs font-bold text-white uppercase transition-all duration-150 ease-linear bg-red-800 rounded outline-none active:bg-indigo-600 focus:outline-none"
                    href="/admin/settings">
                    Durchsuchen
                    </a>
                {/if}
                <InfoBubble infoText="Stellt die Anzahl der Studienteilnehmenden in jeder Dekade dar. Falls die Person älter als 120 Jahre alt ist, wird diese in die Dekade 110 eingeordnet." />
            </div>
        </div>
    </div>
    <div class="flex-auto p-4">
        <div class="relative h-350-px">
            <canvas id="age-distribution-chart"></canvas>
        </div>
    </div>
</div>
{#if showPopup}
  <BarChartPopup_2 {popupTitle} {labels} {values} on:close={closePopup} />
{/if}