<script>
  import { onMount } from "svelte";
  import Chart from "chart.js";
  import PieChartPopup from "../Graphs/PieChartPopup.svelte"; // Updated import
  import InfoBubble from '../Graphs/InfoBubble.svelte';

  export let overviewData;

  let showPopup = false;
  let chart;

  let popupTitle = "Geschlechtsverteilung";

  const data = [overviewData["GenderGroup"]["männlich"], 
                    overviewData["GenderGroup"]["weiblich"], 
                    overviewData["GenderGroup"]["unbekannt"], 0];

  onMount(() => {
    if (Object.keys(overviewData).length > 0) {
      initializeChart();
    }
  });

  // Initialize chart
  function initializeChart() {
      const ctx = document.getElementById("pie-chart").getContext("2d");
      chart =  new Chart(ctx, {
        type: "pie",
        data: {
          labels: ["Männlich", "Weiblich", "Unbekannt"],
          datasets: [
            {
              data: data,
              backgroundColor: [
                "rgba(179, 53, 40, 0.4)", // Semi-transparent red
                "rgba(237, 100, 166, 0.4)", // Semi-transparent pink
                "rgba(236, 201, 75, 0.4)" // Semi-transparent yellow
              ],
              hoverBackgroundColor: [
                "rgba(179, 53, 40, 1)", // Solid red
                "rgba(237, 100, 166, 1)", // Solid pink
                "rgba(236, 201, 75, 1)" // Solid yellow
              ]
            }
          ]
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          title: {
            display: true,
            fontColor: "#4a5568",
          },
          legend: {
            labels: {
              fontColor: "#4a5568",
            },
            position: "bottom",
          },
          tooltips: {
            mode: "index",
            intersect: false,
          },
          hover: {
            mode: "nearest",
            intersect: true,
          },
        },
      });
    }

    function openPopup() {
        showPopup = true;
    }

    function closePopup() {
        showPopup = false;
    }
    function handleKeyPress(event) {
    if (event.key === 'Enter' || event.key === ' ') {
      openPopup();
    }
  }
      
</script>

<style>
  .chart-container {
    position: relative;
    width: 100%;
    height: 349px; /* Adjust height as needed */
  }

  canvas {
    display: block;
    width: 100% !important;
    height: 100% !important;
  }
</style>

<div class="relative flex flex-col w-full min-w-0 break-words bg-white rounded shadow-lg">
  <div class="px-4 py-3 mb-0 bg-transparent rounded-t">
    <div class="flex flex-wrap items-center">
      <div class="relative flex-1 flex-grow w-full max-w-full">
        <h6 class="mb-1 text-xs font-semibold uppercase text-blueGray-600">
          Geschlechtsverteilung
            <span class="block mt-2 px-2 py-1 bg-gray-100 rounded shadow text-sm font-bold text-gray-700 ">
              Studienteilnehmende: {overviewData["TotalPatient"]}
            </span>
        </h6>
      </div>
      <div class="relative flex-1 flex-grow max-w-full px-4 text-right ">
        <button class="px-6 py-1 mb-1 mr-1 text-xs font-bold text-white uppercase transition-all duration-150 ease-linear bg-red-800 rounded outline-none active:bg-indigo-600 focus:outline-none" on:click={openPopup} on:keydown={handleKeyPress}>
          Zoom
        </button>
        {#if window.location.pathname !== '/admin/results'}
        <a
          class="px-3 py-1 mb-1 mr-1 text-xs font-bold text-white uppercase transition-all duration-150 ease-linear bg-red-800 rounded outline-none active:bg-indigo-600 focus:outline-none w-min"
          href="/admin/settings"
        >
        Durchsuchen
        </a>
        {/if}
        <InfoBubble infoText="Darstellung des prozentualen Anteils der einzelnen Geschlechter." />
      </div>
    </div>
  </div>
  <div class="flex-auto p-4">
    <div class="chart-container">
      <canvas id="pie-chart"></canvas>
    </div>
  </div>
</div>
{#if showPopup}
  <PieChartPopup {popupTitle} {data} on:close={closePopup} />
{/if}