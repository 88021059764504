<script>
  import { writable } from 'svelte/store';
  import ResultsTable from './ResultsElements/ResultsTable.svelte';
  import CardStats from "./CardStats.svelte";
  import PieChart from './ResultsElements/PieChart.svelte';
  import BarChart from './ResultsElements/BarChart.svelte';
  import { currentPage } from '../../store.ts'; // Import the store
  import FilterPopup from '../Graphs/FilterPopup.svelte';

  let data = [];
  let filters = {};
  let selectedGraph = 'Table';
  const graphs = writable([]);
  const API_URL = process.env.API_URL;
  let showPopup = false;

  async function fetchFormData() {
    try {
      const response = await fetch(`${API_URL}/get_form_data`);
      const result = await response.json();
      if (result.status === 'success') {
        data = result.data;
      }
    } catch (error) {
      console.error('Error fetching form data:', error);
    }
  }

  async function fetchFilters() {
    try {
        const response = await fetch(`${API_URL}/get_filter`, {
            method: 'GET',
            credentials: 'include'  // Include cookies with the request
        });
        const result = await response.json();
        console.log(result);
        if (result.status === 'success') {
            filters = result.filter;
            console.log(filters);
        }
    } catch (error) {
        console.error('Error fetching filters:', error);
    }
  }

  function addGraph() {
    graphs.update(currentGraphs => {
      if (currentGraphs.length < 4) {
        return [...currentGraphs, { type: selectedGraph, id: Date.now() }];
      } else {
        alert("You can only add up to 4 graphs at once.");
      }
      return currentGraphs;
    });
  }

  function removeGraph(id) {
    graphs.update(currentGraphs => currentGraphs.filter(graph => graph.id !== id));
  }

  async function handleBackClick() {
    currentPage.set('search');
    window.location.href = "/admin/settings";
  }

  function firstFiveTrueEntries(object) {
    return Object.entries(object).filter(([key, value]) => value).slice(0, 5);
  }

  function handleKeyDown(event) {
    if (event.key === 'Enter' || event.key === ' ') {
      showPopup = true;
    }
  }

  function openPopup() {
    showPopup = true;
  }

  function closePopup() {
    showPopup = false;
  }

  fetchFormData();
  fetchFilters();
</script>

<h2 class="text-2xl font-bold mb-4">Results</h2>

<!-- Selected Filters Section -->
<div class="flex flex-wrap p-3 border mb-4">
  <div class="font-bold px-4 w-full lg:w-3/12 align-text-bottom"><span>Ausgewählte Filter:</span></div>
  <div class="flex flex-wrap w-full">
    {#if filters}
      {#each firstFiveTrueEntries(filters) as [key, value]}
        {#if value}
          <div 
            class="px-4 py-2 border bg-white m-2" 
            on:click={openPopup}
            on:keydown={handleKeyDown} 
            tabindex="0"
            role="button"
            aria-pressed="false"
          >
            {key}: {value}
          </div>
        {/if}
      {/each}
    {/if}
    <div class="flex-grow flex justify-end items-right">
      <button type="button" class="px-4 py-2 bg-red-500 hover:bg-red-700 text-white rounded" on:click={handleBackClick}>zurück zur Abfrage</button>
    </div>
  </div>
</div>

<!-- Popup Modal for All Filters -->
{#if showPopup}
  <FilterPopup {filters} closePopup={closePopup} />
{/if}

<!-- Card stats -->
<div class="flex flex-wrap mb-4 p-3 border">
  <div class="font-bold px-4 w-full lg:w-3/12 align-text-bottom"><span>Ergebnis:</span></div>
  <div class="w-full px-4 lg:w-4/12 xl:w-3/12">
    <CardStats
      statSubtitle="Patienten"
      statTitle={data.length}
      statIconName="far fa-chart-bar"
      statIconColor="bg-red-600"
    />
  </div>
  <div class="w-full px-4 lg:w-4/12 xl:w-3/12">
    <CardStats
      statSubtitle="Bioproben"
      statTitle="121"
      statPercentColor="text-red-500"
      statIconName="fas fa-chart-pie"
      statIconColor="bg-red-600"
    />
  </div>
  <div class="controls flex-grow flex justify-end items-right space-x-4">
    <select bind:value={selectedGraph}>
      <option value="Table">Table</option>
      <option value="PieChart">Pie Chart</option>
      <option value="BarChart">Bar Chart</option>
    </select>
    <button on:click={addGraph} class="add-button hover:bg-red-700">+ Add Graph</button>
  </div>
</div>

<div class="perimeter-container">
  <div class="grid-container">
    {#each $graphs as graph, index (graph.id)}
      <div class="grid-item">
        <div class="header">
          <button on:click={() => removeGraph(graph.id)} class="close-button">X</button>
        </div>
        <div class="content">
          {#if graph.type === 'Table'}
            {#if data && Object.keys(data).length > 0}
              <ResultsTable {data} />
            {:else}
              <p>No data available.</p>
            {/if}
          {:else if graph.type === 'PieChart'}
            <PieChart id={graph.id} useLoadedData={true} />
          {:else if graph.type === 'BarChart'}
            <BarChart id={graph.id} />
          {/if}
        </div>
      </div>
    {/each}
  </div>
</div>

<style>
  .controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
    margin-right: 20px;
  }
  .add-button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
  }
  .perimeter-container {
    border: 2px dashed #ccc;
    padding: 10px;
    margin: 20px auto;
    height: 120%;
    width: 100%;
    position: relative;
  }
  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
    height: 100%;
  }
  .grid-item {
    background-color: white;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    position: relative;
    display: flex;
    flex-direction: column;
    width: 750px;  /* Adjusted width */
    height: 500px;  /* Adjusted height */
  }
  .header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 5px;
    background-color: #f5f5f5;
    border-bottom: 1px solid #ddd;
  }
  .close-button {
    background-color: #f44336;
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 1.2rem;
    line-height: 1;
  }
  .content {
    flex: 1;
    overflow: auto;
  }
</style>
