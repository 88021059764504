<script>
  import { link } from "svelte-routing";
  import { currentPage } from '../../store.ts';
  import { get } from 'svelte/store';

  import NotificationDropdown from "components/Dropdowns/NotificationDropdown.svelte";
  import UserDropdown from "components/Dropdowns/UserDropdown.svelte";

  let collapseShow = "hidden";

  function toggleCollapseShow(classes) {
    collapseShow = classes;
  }

  export let location;

  async function handleSucheClick() {
    if (get(currentPage) !== 'results') {
      currentPage.set('search');
      window.location.href = "/admin/settings";
    }else{
      window.location.href = "/admin/results";
    }
  }

  function handleKeydown(event) {
    if (event.key === 'Enter' || event.key === ' ') {
      handleSucheClick();
    }
  }
</script>

<nav class="relative z-10 flex flex-wrap items-center justify-between px-6 py-4 bg-white shadow-xl md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden md:w-64">
  <div class="flex flex-wrap items-center justify-between w-full px-0 mx-auto md:flex-col md:items-stretch md:min-h-full md:flex-nowrap">
    <!-- Toggler -->
    <button class="px-3 py-1 text-xl leading-none text-black bg-transparent border border-transparent border-solid rounded opacity-50 cursor-pointer md:hidden" type="button" on:click={() => toggleCollapseShow('bg-white m-2 py-3 px-6')}>
      <i class="fas fa-bars"></i>
    </button>
    <!-- Brand -->
    <a use:link class="inline-block p-4 px-0 mr-0 text-sm font-bold text-left uppercase md:block md:pb-2 text-blueGray-600 whitespace-nowrap relative" href="https://dzhk.de/dzhk-heart-bank">
      <img class="me-2" src="../assets/img/DZHK_logo.jpg" style="height:50px" alt="DZHK Logo" loading="lazy" />
      <span class="absolute right-0 text-xs font-medium text-blueGray-500" style="top: 60px; right: 30px;">v0.01q</span> <!-- Version number positioned -->
    </a>

    <a use:link class="p-1 px-0 md:4/6 md:justify-end" href="https://dzhk.de/dzhk-heart-bank">
      <img class="me-2" src="../assets/img/Heartbank_logo.jpg" style="height:40px" alt="DZHK-Heartbank Logo" loading="lazy" />
    </a>
    <!-- Divider -->
    <hr class="my-4 md:min-w-full" />
    <!-- Navigation -->
    <ul class="flex flex-col list-none md:flex-col md:min-w-full">
      <li class="items-center">
        <a use:link href="/admin/dashboard" class="text-xs uppercase py-3 font-bold block {location.href.indexOf('/admin/dashboard') !== -1 ? 'text-red-500 hover:text-red-600':'text-blueGray-700 hover:text-blueGray-500'}">
          <i class="fas fa-tv mr-2 text-sm {location.href.indexOf('/admin/dashboard') !== -1 ? 'opacity-75' : 'text-blueGray-300'}"></i>
          Startseite
        </a>
      </li>
      <li class="items-center">
        <button 
          on:click={handleSucheClick} 
          on:keydown={handleKeydown} 
          class="text-xs uppercase py-3 font-bold block {location.href.indexOf('/admin/settings') !== -1 || location.href.indexOf('/admin/results') !== -1 ? 'text-red-500 hover:text-red-600':'text-blueGray-700 hover:text-blueGray-500'} cursor-pointer"
        >
          <i class="fas far fa-search mr-2 text-sm {location.href.indexOf('/admin/settings') !== -1 || location.href.indexOf('/admin/results') !== -1 ? 'opacity-75' : 'text-blueGray-300'}"></i>
          Datensuche
        </button>
      </li>

      <li class="items-center">
        <a use:link href="/admin/tables" class="text-xs uppercase py-3 font-bold block {location.href.indexOf('/admin/tables') !== -1 ? 'text-red-500 hover:text-red-600':'text-blueGray-700 hover:text-blueGray-500'}">
          <i class="fas fa-table mr-2 text-sm {location.href.indexOf('/admin/tables') !== -1 ? 'opacity-75' : 'text-blueGray-300'}"></i>
          Datenabruf
        </a>
      </li>
    </ul>
    <!-- Navigation -->
    <!-- Divider -->
    <hr class="my-4 md:min-w-full" />
    <!-- Navigation -->
    <ul class="flex flex-col list-none md:flex-col md:min-w-full md:mb-4">
      <li class="inline-flex">
        <a use:link href="/admin/support" class="block mb-4 text-sm font-semibold no-underline text-blueGray-700 hover:text-blueGray-500">
          <i class="mr-2 text-base fas fa-question text-blueGray-300"></i>
          Hilfe/Support
        </a>
      </li>
<!--       <li class="inline-flex">
        <a use:link href="/admin/dashboard" class="block mb-4 text-sm font-semibold no-underline text-blueGray-700 hover:text-blueGray-500">
          <i class="mr-2 text-base fas fa-comments text-blueGray-300"></i>
          Chat
        </a>
      </li> -->
    </ul>
  </div>
</nav>

