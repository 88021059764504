<script>
  import { onMount } from "svelte";
  import Chart from "chart.js";
  export let id;
  export let useLoadedData = false;

  const API_URL = process.env.API_URL;

  async function fetchData(endpoint) {
    const response = await fetch(`${API_URL}/${endpoint}?use_loaded_data=${useLoadedData}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return response.json();
  }

  onMount(async () => {
    try {
      const data = await fetchData("pie_stats_combined");
      const config = {
        type: "pie",
        data: {
          labels: ["Männlich", "Weiblich", "Nicht angegeben"],
          datasets: [
            {
              data: [data.Male, data.Female, data["Not Assessed"]],
              backgroundColor: [
                "rgba(179, 53, 40, 0.4)",
                "rgba(237, 100, 166, 0.4)",
                "rgba(236, 201, 75, 0.4)"
              ],
              hoverBackgroundColor: [
                "rgba(179, 53, 40, 1)",
                "rgba(237, 100, 166, 1)",
                "rgba(236, 201, 75, 1)"
              ]
            }
          ]
        },
        options: {
          maintainAspectRatio: false,
          responsive: true,
          title: {
            display: true,
            text: `Geschlechtsverteilung in Prozent`,
            fontColor: "#4a5568",
          },
          legend: {
            labels: {
              fontColor: "#4a5568",
            },
            position: "bottom",
          },
          tooltips: {
            mode: "index",
            intersect: false,
          },
          hover: {
            mode: "nearest",
            intersect: true,
          },
        },
      };
      const ctx = document.getElementById(`pie-chart-${id}`).getContext("2d");
      new Chart(ctx, config);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  });
</script>

<style>
  .chart-container {
    position: relative;
    width: 100%;
    height: 349px;
  }

  canvas {
    display: block;
    width: 100% !important;
    height: 100% !important;
  }
</style>

<div class="relative flex flex-col w-full min-w-0 break-words bg-white rounded shadow-lg">
  <div class="px-4 py-3 mb-0 bg-transparent rounded-t">
    <div class="flex flex-wrap items-center">
      <div class="relative flex-1 flex-grow w-full max-w-full">
        <h6 class="mb-1 text-xs font-semibold uppercase text-blueGray-600">
          Geschlechtsverteilung
        </h6>
      </div>
    </div>
  </div>
  <div class="flex-auto p-4">
    <div class="chart-container">
      <canvas id={`pie-chart-${id}`}></canvas>
    </div>
  </div>
</div>
