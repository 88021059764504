<div
  class="relative flex flex-col w-full min-w-0 mb-6 break-words border-0 rounded-lg shadow-lg bg-blueGray-100"
>
  <div class="px-6 py-3 mb-0 bg-white border-4 rounded-t">
    <div class="flex justify-between pb-2 text-center">
      <h6 class="text-red-600 text-1xl font-semi">Datenschutzerklärung</h6>
    </div>
    <div class="flex-auto px-0 py-0 pt-0 lg:px-2">
      <form>
        <div class="p-4 m-2 bg-gray-200 rounded-lg shadow-md">
          <h3><strong><u>1. Allgemeine Hinweise:</u> </strong></h3>
          <p class="pt-1 pb-2" style="text-align: justify;">
            Der Betreiber dieser Seiten nimmt den Schutz Ihrer persönlichen
            Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten
            vertraulich und entsprechend der gesetzlichen
            Datenschutzvorschriften sowie dieser Datenschutzerklärung. Wenn Sie
            diese Website besuchen, werden verschiedene personenbezogene Daten
            erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich
            identifiziert werden können. Die vorliegende Datenschutzerklärung
            erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie
            erläutert auch, wie und zu welchem ​​Zweck das geschieht. Wir weisen
            darauf hin, dass die Datenübertragung im Internet (z.B. bei der
            Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein
            lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht
            möglich.
          </p>
          <h3><u>1.1 Verantwortliche Stellen:</u></h3>
          <p class="pt-1 pb-2" style="text-align: justify;">
            Georg-August-Universität Göttingen Stiftung Öffentlichen Rechts
            Universitätsmedizin Göttingen (nachfolgend auch „wir“ oder „uns“
            oder „Verantwortlicher“ genannt) Umsetzung und Kontrolle der
            Datenschutzvorschriften durch Prof. Dr. rer. nat. Dagmar Krefting
            Institut für Medizinische Informatik Robert-Koch-Straße 40 37075
            Göttingen E-Mail: mi.kontakt@med.uni-goettingen.de
          </p>
          <h3><u>1.2 Datenschutzbeauftragter*in: </u></h3>
          <p class="pt-1 pb-2" style="text-align: justify;">
            Bei Fragen zur Verarbeitung Ihrer personenbezogenen Daten oder Ihren
            Rechten im Zusammenhang mit dem Datenschutz wenden Sie sich bitte an
            Universitätsmedizin Göttingen - Datenschutzbeauftragter - 37099
            Göttingen Telefon: 0551 3962762 E-Mail:
            datenschutz@med.uni-goettingen.de Bei Fragen zur Verarbeitung Ihrer
            personenbezogenen Daten oder zur Geltendmachung Ihrer Rechte wenden
            Sie sich bitte an dzhk.support@med.uni-goettingen.de.
          </p>
          <h3><strong><u>2. Rechte der Nutzer*innen: </u></strong></h3>
          <p class="pt-1 pb-2" style="text-align: justify;">
            Werden personenbezogene Daten von Ihnen verarbeitet, sind Sie
            Betroffener i.S.d. DSGVO und es steht Ihnen das Recht zu, Ihre
            Betroffenenrechte geltend zu machen. Ihnen stehen hinsichtlich der
            Verarbeitung Ihrer personenbezogenen Daten durch uns folgende Rechte
            zu: - Recht auf Widerruf der datenschutzrechtlichen
            Einwilligungserklärung (Art. 7 Abs. 3 DSGVO) - Recht auf Auskunft
            (Art. 15 DSGVO) - Recht auf Berichtigung (Art. 16 DSGVO) - Recht auf
            Löschung (Art. 17 DSGVO) - Recht auf Einschränkung der Verarbeitung
            (Art. 18 DSGVO) - Recht auf Datenübertragbarkeit (Art. 20 DSGVO) -
            Widerspruchsrecht gegen die Verarbeitung (Art. 21 DSGVO) sowie -
            Beschwerderecht bei einer Aufsichtsbehörde (Art. 13 Abs. 2 lit. d
            DSGVO).
          </p>
          <h3>
            <u>
              2.1 Recht auf Widerruf der datenschutzrechtlichen
              Einwilligungserklärung:</u>
          </h3>
          <p class="pt-1 pb-2" style="text-align: justify;">
            Sofern die Verarbeitung Ihrer personenbezogenen Daten auf Ihrer
            Einwilligung beruht, haben Sie das Recht, Ihre datenschutzrechtliche
            Einwilligungserklärung jederzeit mit Wirkung für die Zukunft zu
            widerrufen. Durch den Widerruf der Einwilligung wird die
            Rechtmäßigkeit der aufgrund der Einwilligung bis zum Widerruf
            erfolgten Verarbeitung nicht berührt. Sie können Ihre Einwilligung
            widerrufen, indem Sie uns eine E-Mail an:
            dzhk.support@med.uni-goettingen.de senden.
          </p>
          <h3>
            <u>2.2 Recht auf Widerruf der datenschutzrechtlichen
              Einwilligungserklärung:</u>
          </h3>
          <p class="pt-1 pb-2" style="text-align: justify;">
            Sie können im Rahmen der gesetzlichen Bestimmungen jederzeit von uns
            Auskunft darüber verlangen, ob personenbezogene Daten von uns
            verarbeitet werden. Ist dies der Fall, so haben Sie das Recht, über
            den Umfang der Datenverarbeitung Auskunft zu verlangen.
          </p>
          <h3><u>2.3 Recht auf Berichtigung:</u></h3>
          <p class="pt-1 pb-2" style="text-align: justify;">
            Sie haben ein Recht auf Berichtigung und/oder Vervollständigung
            Ihrer bei uns gespeicherten Daten, sofern die verarbeiteten
            personenbezogenen Daten, die Sie betreffen, unrichtig oder
            unvollständig sind.
          </p>
          <h3><u>2.4 Recht auf Löschung:</u></h3>
          <p class="pt-1 pb-2" style="text-align: justify;">
            Sie haben das Recht, die unverzügliche Löschung Sie betreffender
            personenbezogener Daten zu verlangen, sofern die Voraussetzungen
            hierfür vorliegen.
          </p>
          <h3>
            <u>2.5 Recht auf Einschränkung der Verarbeitung: </u>
          </h3>
          <p class="pt-1 pb-2" style="text-align: justify;">
            Liegen die Voraussetzungen hierfür vor, können Sie die Einschränkung
            der Verarbeitung Ihrer personenbezogenen Daten verlangen. Das Recht
            auf Einschränkung der Verarbeitung besteht nicht, sofern die
            Verarbeitung für die technische Bereitstellung und Nutzung oder
            aufgrund rechtlicher Vorgaben erforderlich ist.
          </p>
          <h3><u>2.6 Recht auf Datenübertragbarkeit:</u></h3>
          <p class="pt-1 pb-2" style="text-align: justify;">
            Sie haben das Recht, die Sie betreffenden personenbezogenen Daten,
            die Sie dem Verantwortlichen bereitgestellt haben, in einem
            strukturierten, gängigen und maschinenlesbaren Format zu erhalten.
            Außerdem haben Sie das Recht diese Daten einem anderen
            Verantwortlichen ohne Behinderung durch den Verantwortlichen, dem
            die personenbezogenen Daten bereitgestellt wurden, zu übermitteln,
            sofern die Voraussetzungen hierfür vorliegen.
          </p>

          <h3><u>2.7 Widerspruchsrecht: </u></h3>
          <p class="pt-1 pb-2" style="text-align: justify;">
            Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen
            Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender
            personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 lit. e oder
            f DSGVO erfolgt, Widerspruch einzulegen. Der Widerspruch hat zur
            Folge, dass der Verantwortliche die Sie betreffenden
            personenbezogenen Daten nicht mehr verarbeitet, es sei denn, er kann
            zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die
            Ihre Interessen, Rechte und Freiheiten überwiegen, oder die
            Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung
            von Rechtsansprüchen. Ihr Widerspruchsrecht kann insoweit beschränkt
            werden, als es voraussichtlich die Verwirklichung der Forschungs-
            oder Statistikzwecke unmöglich macht oder ernsthaft beeinträchtigt
            und die Beschränkung für die Erfüllung der Forschungs- oder
            Statistikzwecke notwendig ist.
          </p>
          <h3>
            <u>2.8 Recht auf Beschwerde bei einer Aufsichtsbehörde:
            </u>
          </h3>
          <p class="pt-1 pb-2" style="text-align: justify;">
            Unbeschadet eines anderweitigen verwaltungsrechtlichen oder
            gerichtlichen Rechtsbehelfs steht Ihnen das Recht auf Beschwerde bei
            einer Aufsichtsbehörde zu, wenn Sie der Ansicht sind, dass die
            Verarbeitung der Sie betreffenden personenbezogenen Daten gegen die
            DSGVO verstößt. Zuständige Aufsichtsbehörde in
            datenschutzrechtlichen Fragen ist der Landesdatenschutzbeauftragte
            des Bundeslandes, in dem diese Website betrieben wird. Der
            Landesbeauftragte für den Datenschutz Niedersachsen Prinzenstraße 5
            30159 Hannover Telefon: 0511-120 4500 Telefax: 0511-120 4599 E-Mail:
            poststelle@lfd.niedersachsen.de
          </p>
          <h3>
            <strong
              ><u>3. Datenerhebung im Rahmen des Besuchs unserer Website:
            </u></strong>
          </h3>
          <p class="pt-1 pb-2" style="text-align: justify;"></p>
          <h3>
            <u>3.1 Technisch erforderliche Daten Bei der Nutzung der Website zur
              Informationssuche:</u>
          </h3>
          <p class="pt-1 pb-2" style="text-align: justify;">
            Also wenn Sie sich nicht registrieren oder uns anderweitig
            Informationen übermitteln, erheben wir nur die personenbezogenen
            Daten, die Ihr Browser an unseren Server übermittelt. Wenn Sie
            unsere Website aufrufen, erheben wir die folgenden Daten, die für
            uns technisch erforderlich sind, um die Funktionsfähigkeit der
            Website sowie die Stabilität und Sicherheit unserer Systeme zu
            gewährleisten und die Optimierung der Website zu ermöglichen: –
            IP-Adresse; – Datum und Uhrzeit des Zugriffs auf diese Website; –
            Zeitzonendifferenz zur Greenwich Mean Time (GMT); – Inhalt der
            Anforderung (konkrete Seite); – Zugriffsstatus/HTTP-Statuscode; –
            jeweils übertragene Datenmenge; – Website, von der die Anforderung
            kommt; – Browsertyp; – Betriebssystem und dessen Oberfläche; –
            Sprache und Version der Browsersoftware; – Der
            Internet-Service-Provider des zugreifenden Systems und – sonstige
            ähnliche Daten und Informationen, die der Gefahrenabwehr im Falle
            von Angriffen auf unsere Systeme dienen. Die Daten werden ebenfalls
            in den Logfiles unseres Systems gespeichert. Eine Speicherung dieser
            Daten zusammen mit anderen personenbezogenen Daten des Nutzers
            findet nicht statt. Eine Auswertung der Daten zu Marketingzwecken
            findet in diesem Zusammenhang nicht statt. Grundlage für die
            Verarbeitung dieser Daten ist Art. 6 Abs. 1 lit. b DSGVO. Die
            vorübergehende Speicherung der IP-Adresse durch das System ist
            notwendig, um eine Auslieferung der Website an den Rechner des
            Nutzers zu ermöglichen. Hierfür muss die IP-Adresse des Nutzers für
            die Dauer der Sitzung gespeichert bleiben. Eine statistische
            Auswertung der IP-Adresse kann erforderlich sein, um Angriffe auf
            die Verfügbarkeit der Website oder die Vertraulichkeit der Inhalte
            und Daten der Website erkennen und verhindern zu können sowie im
            Falle einer Straftat zur Anzeige bei der Polizei zu gelangen. Die
            Daten werden gelöscht, sobald sie für die Erreichung des Zweckes
            ihrer Erhebung nicht mehr erforderlich sind. Im Falle der Erfassung
            der Daten zur Bereitstellung der Website ist dies der Fall, wenn die
            jeweilige Sitzung beendet ist. Im Falle der Speicherung der Daten in
            Logfiles ist dies nach spätestens sieben Tagen der Fall.
          </p>
          <h3><u>3.2 Kontaktaufnahme: </u></h3>
          <p class="pt-1 pb-2" style="text-align: justify;">
            Wenn Sie uns Anfragen zukommen lassen, werden Ihre angegebenen
            Kontaktdaten zwecks Bearbeitung der Anfrage und für den Fall von
            Anschlussfragen bei uns gespeichert. Welche Kontaktdaten Sie
            angeben, ist Ihnen freigestellt; die Angabe ist nicht zwingend,
            jedoch müssen Sie mindestens eine Kontaktmöglichkeit (Adresse,
            Telefon oder E-Mail) angeben, damit wir Ihr Anliegen bearbeiten
            können. Die von Ihnen eingegebenen Daten geben wir nicht ohne Ihre
            Einwilligung weiter. Ist dies geplant, werden wir zuvor Ihre
            Einwilligung einholen. Rechtsgrundlage ist Art. 6 Abs. 1 lit. f
            DSGVO. Unser berechtigtes Interesse liegt in der Beantwortung Ihres
            Anliegens. Bezieht sich die Kontaktanfrage auf einen bestehenden
            Vertrag, so ist Rechtsgrundlage für die Verarbeitung Art. 6 Abs. 1
            lit. b DSGVO. Im Regelfall löschen wir Ihre personenbezogenen Daten
            nach Beendigung des DZHK-Infrastrukturprojektes, es sei denn, wir
            sind zu einer längeren Aufbewahrung verpflichtet, um unseren
            rechtlichen Verpflichtungen nachzukommen. Die Bereitstellung
            personenbezogener Daten ist weder gesetzlich noch vertraglich
            vorgeschrieben. Wenn Sie Ihre Daten nicht bereitstellen, können wir
            nicht (weiterhin) mit Ihnen kommunizieren.
          </p>
          <h3><u>3.3 Cookies: </u></h3>
          <p class="pt-1 pb-2" style="text-align: justify;">
            Diese Website verwendet keine Cookies
          </p>
          <h3>
            <u>3.4 Empfänger personenbezogener Daten: </u>
          </h3>
          <p class="pt-1 pb-2" style="text-align: justify;">
            Die Verarbeitung personenbezogener Daten im Rahmen dieser Seite
            erfolgt durch die UMG. Darüber hinaus werden Ihre personenbezogenen
            Daten nur im Rahmen der geltenden Datenschutzgesetze weitergegeben,
            - wenn wir gesetzlich oder gerichtlich dazu verpflichtet sind, - an
            Strafverfolgungsbehörden oder andere staatliche Stellen, oder - wenn
            wir der Ansicht sind, dass die Weitergabe notwendig oder angemessen
            ist, um körperlichen oder finanziellen Schaden zu verhindern, oder
            im Zusammenhang mit der Untersuchung mutmaßlicher oder tatsächlicher
            betrügerischer oder illegaler Aktivitäten.
          </p>
          <h3>
            <u>3.5 Keine Datenübermittlung in Drittländer: </u>
          </h3>
          <p class="pt-1 pb-2" style="text-align: justify;">
            Es werden keine personenbezogenen Daten in Länder außerhalb der EU
            übermittelt.
          </p>
          <h3><u>3.6 SSL- bzw. TLS-Verschlüsselung: </u></h3>
          <p class="pt-1 pb-2" style="text-align: justify;">
            Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
            Übertragung vertraulicher Inhalte, wie zum Beispiel der Anfragen,
            die Sie an uns als Seitenbetreiber senden, eine SSL-bzw.
            TLS-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie
            daran, dass die Adresszeile des Browsers von “http://” auf
            “https://” wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
            Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
            Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
            werden.
          </p>
        </div>
      </form>
    </div>
  </div>
</div>
