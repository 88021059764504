<script>
  import { Router, Route } from "svelte-routing";

  // components for this layout
  import AdminNavbar from "components/Navbars/AdminNavbar.svelte";
  import Sidebar from "components/Sidebar/Sidebar.svelte";
  import FooterAdmin from "components/Footers/FooterAdmin.svelte";

  // pages for this layout
  import Dashboard from "views/admin/Dashboard.svelte";
  import Settings from "views/admin/Settings.svelte";
  import Tables from "views/admin/Tables.svelte";
  import Support from "views/admin/Support.svelte";
  import Datasecurity from "views/admin/Datasecurity.svelte";
  import Legal_Notice from "views/admin/Legal_Notice.svelte";

  export let location;
  //export let admin = "";

</script>

<div>
  <Sidebar location={location}/>
  <div class="relative md:ml-64 bg-blueGray-100">
    <AdminNavbar />
    <div class="w-full px-4 mx-auto md:px-10">
      <Router url="admin">
        <Route path="dashboard" component="{Dashboard}" />
        <Route path="settings" component="{Settings}" />
        <Route path="tables" component="{Tables}" />
        <Route path="support" component="{Support}" />
      </Router>
      <div>
        <Dashboard />
      </div>
      <Router url="admin">
        <Route path="datasecurity" component="{Datasecurity}" />
         <Route path="legal_notice" component="{Legal_Notice}" />
      </Router>
      <FooterAdmin />
  </div>
  </div>
</div>
